
import {Link} from 'react-router-dom';
import DashboardMenu from "./DashboardMenu";
import DashboardFooter from "./DashboardFooter";

import mercado_garden_3 from '../../images/mercado-garden-3.jpg';
import mercado_pool_3 from '../../images/mercado-pool-3.jpg';
import mercado_plants_2 from '../../images/mercado-plants-2.jpg';
import mercado_pool_1 from '../../images/mercado-pool-1.jpg';
import mercado_garden_5 from '../../images/mercado-garden-5.jpg';
import mercado_6 from '../../images/mercado-6.jpg';

import mercado_4 from '../../images/mercado-4.jpg';
import mercado_elec_2 from '../../images/mercado-elec-2.jpeg';
import mercado_garden_2 from '../../images/mercado-garden-2.jpg';
import { useEffect, useState } from 'react';
import axios from 'axios';



function Chat() {
    document.title='Mecado Escolar Chat';

    let mercadouser=null;
    if(localStorage.getItem('mercadouser')===null){
        mercadouser=false
    }
    else{
        mercadouser=JSON.parse(localStorage.getItem('mercadouser'))
    }

    const [users,setUsers]=useState([]);
    const [chats,setChats]=useState([]);
    const [message, setMessage]=useState('');
    const [receiver, setReceiver]=useState('');

    const saveChat = async(e) =>{
        e.preventDefault();

        if(receiver.trim()===""){
            alert("Please select receiver");
        }else if(message.trim()===""){
            alert("Please enter message");
        }else{
            let frmdata=new FormData();
            frmdata.append('action', 'savechat');
            frmdata.append('sender', mercadouser[0]['id']);
            frmdata.append('receiver', receiver);
            frmdata.append('message', message);
    
            axios.post(process.env.REACT_APP_PHP_URL+"chat.php",frmdata,null)
            .then(response=>{
                getChats(receiver);
                setMessage('');
                alert(response.data);
            })
            .catch(error=>{
                alert(error);
            });
        }

        
    }


    const getChats = async(id) =>{
        setReceiver(id);
        let frmdata=new FormData();
        frmdata.append('owner', id);
        frmdata.append('user', mercadouser[0]['id']);
        frmdata.append('action', 'fetchchats');
        axios.post(process.env.REACT_APP_PHP_URL+"chat.php",frmdata,null)
        .then(response=>{
            setChats([]);
            setChats(response.data.chats);
        })
        .catch(error=>{
            setChats([]);
        });
    }

    useEffect(() => {
        getUsers();
      }, []);

    const getUsers = async(e) =>{
        let frmdata=new FormData();
        frmdata.append('action', 'fetchusers');
        frmdata.append('user', mercadouser[0]['id']);
        axios.post(process.env.REACT_APP_PHP_URL+"chat.php",frmdata,null)
        .then(response=>{
            const resp = response.data.users;
                setUsers(resp);
                getChats(resp[0].id);
        })
        .catch(error=>{
            setUsers([]);
        });
    }

  return (
    <div id="wrapper">
      
      {/* Dashboard Nav Here */}
        <DashboardMenu active='chat' />
            <div class="content-page">
                <h2 class="section-title">Chat with Admin or Business Owners
                <p>Select a Person to chat With</p>
                
            </h2>
            <section id="chat" class="chat">
                <div class="chat-owner">
                    <div class="chat-users">
                    <p>Business Owner</p>
                    </div>
                    <div class="chat-users-lists">
                    {users && users.map((user,key) => (
                        <>
                        {user.role === "Owner" && 
                        <button onClick={()=>getChats(user.id)}>{user.name}</button>
                        }
                        </>
                    ))}
                    </div>
                </div>
                <div class="chat-area">
                <div class="chat-area-title">
                    <p>Active User and Messages</p>
                </div>
                <div class="chat-area-message">
                    
                    {chats && chats.map((chat,key) => (
                        <>
                        {chat.sender === mercadouser[0]['id'] ? 
                        <div class="chat-area-message-in">
                        {chat.message}
                        </div> :
                        <div class="chat-area-message-out">
                        {chat.message}
                        </div>
                        }
                        </>
                    ))}

                </div>
                <form onSubmit={saveChat}>
                <div class="chat-area-send">
                        <input type="text" value={message}  onChange={(e) => setMessage(e.target.value)} name="message" placeholder="Compose Message" />
                        <button>Send</button>
                </div>
                </form>
                
                </div>

                <div class="chat-manager">
                <div class="chat-users">
                    <p>Admin</p>
                </div>
                <div class="chat-users-lists">
                    {users && users.map((user,key) => (
                        <>
                        {user.role === "Admin" && 
                        <button onClick={()=>getChats(user.id)}>{user.name}</button>
                        }
                        </>
                    ))}
                </div>
                </div>
            </section>
            </div>
        <DashboardFooter />

    </div>
  );
}

export default Chat;
