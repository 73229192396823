import {Link} from 'react-router-dom';

function Footer() {
    return (
        <div className="footer-page">
            <section id="footer" className="footer">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                </svg>
                <h6>Get in Touch with us</h6>
                <p>Email: info@mercadoescolar.com</p>
                <p>Phone: +743434242</p>
                <p>Address: Place name</p>
                <p>Call on: +233 43 24432</p>
            </div>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
                </svg>
                <h6>Quick links</h6>
                <p><Link className="quick-links" to="/aboutus">About us</Link></p>
                <p><Link className="quick-links" to="/services">Our services </Link></p>
                <p><Link className="quick-links" to="/contactus">contactus</Link></p>
                <p><Link className="quick-links" to="#">Privacy statement and policy</Link></p>
                <p><Link className="quick-links" to="/register">Get started</Link></p>
            </div>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                </svg>
                <h6>Connect us on.</h6>
                <p><a className="quick-links" href="#">Facebook</a></p>
                <p><a className="quick-links" href="#">Twitter </a></p>
                <p><a className="quick-links" href="#">Instagram</a></p>
                <p><a className="quick-links" href="#">Youtube</a></p>
            </div>

            </section>
        </div>
    );
  }
  
  export default Footer;
  