import Mainmenu from "./Mainmenu";
import {Link} from 'react-router-dom';

function ForgotPassword() {
    document.title='ForgotPassword - Mecado Escolar';
  return (
    <div id="wrapper">
        {/* Main Menu */}
        <Mainmenu active='login' />

            <div class="content-page">
                <h2 class="section-title">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                    </svg>
                    Restricted Area
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                    </svg> 
                </h2>
                <section id="contactus" class="contactus">
                    <div>
                    <p>Provide your Username or email</p>
                    <form>
                        <div class="inputs">
                            <label>Username</label>
                            <input type="email" id="email" name="email" placeholder="Email Address" />
                        </div>

                        <button class="btn">Reset Password</button>
                        <Link to="/login"> Login</Link>
                        
                    </form>
                    </div>
                </section>
            </div>
      
        {/* Footer */}

    </div>
  );
}

export default ForgotPassword;
