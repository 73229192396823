
import {Link} from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import mercado_4 from '../../images/mercado-4.jpg';

function LoadClubs({ownerid}) {
    let mercadouser=null;
    if(localStorage.getItem('mercadouser')===null){
        mercadouser=false
    }
    else{
        mercadouser=JSON.parse(localStorage.getItem('mercadouser'))
    }

    const [clubs,setPosts]=useState([]);
    
    useEffect(()=>{
        let success=true;
        let load='';
        if(ownerid===''){
            load="loadallclubs.php";
        }
        else{
            load="loadclubs.php";
        }

        const getPosts = async (e) => {
            axios({
                method:'GET',
                url:process.env.REACT_APP_PHP_URL+load,
                params:{
                    owner:(ownerid==="")?mercadouser[0]['id']:ownerid,
                    member:mercadouser[0]['id'],
                },
                headers:{
                    'content-type':'application/json',
                }
            })
            .then(response=>{
                if (success) {
                    if(response.data.response){
                        setPosts(response.data.clubs);
                    }
                    else{
                        setPosts([]);
                    }
                }
            })
            .catch(error=>{
                setPosts([]);
            })
        };
        getPosts();

        return ()=>{
            success=false;
        }

    },[clubs])


    const onJoin = (club) => {
        let frmdata=new FormData();
        frmdata.append('clubid',club.id);
        frmdata.append('member',mercadouser[0]['id']);
        axios.post(process.env.REACT_APP_PHP_URL+"joinclub.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }

    const onLeave = (club) => {
        let frmdata=new FormData();
        frmdata.append('id',club);
        frmdata.append('member',mercadouser[0]['id']);
        axios.post(process.env.REACT_APP_PHP_URL+"leaveclub.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }


    const onDelClub = (club) => {
        let frmdata=new FormData();
        frmdata.append('id',club.id);
        axios.post(process.env.REACT_APP_PHP_URL+"deleteclub.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }
    

  return (
    <>
    <section id="visitor" class="visitor">
    {clubs && clubs.map((club,key) => (
        <>
            {mercadouser[0]['role']==="Student" && club.owner!==mercadouser[0]['id'] &&
                <div class="get-started" key={key}>
                            
                    <div class="visitor-illustration">
                        <img src={process.env.REACT_APP_PHP_URL+club.uploadedphoto}/>
                        <p>{club.clubname}</p>
                    </div>

                    <div>
                        <p>{club.clubinformation} </p>
                    </div>

                    <div class="visit-apartment">
                        {club.member &&
                            <button class="btn" onClick={() => onLeave(club.member)}>Leave <span class="comments">{club.members}</span></button>
                        }

                        {!(club.member) &&
                            <button class="btn" onClick={() => onJoin(club)}>Join <span class="comments">{club.members}</span></button>
                        }
                        <button class="btn">View</button>
                        
                    </div>
                </div>
            }

            {mercadouser[0]['role']!=="Student" &&
                <div class="get-started" key={key}>
                    <p>{club.ownername}</p>
                    <div class="visitor-illustration">
                        <img src={process.env.REACT_APP_PHP_URL+club.uploadedphoto}/>
                        <p>{club.clubname} <span class="comments">{club.members}</span></p>
                    </div>

                    <div>
                        <p>{club.clubinformation} </p>
                    </div>

                    <div class="visit-apartment">
                        <button class="btn" onClick={() => onDelClub(club)}>Delete </button>
                    </div>
                </div>
            }
        </>
                                           
    ))} 
        
    </section>  


        
    </>
    
    
  );
}

export default LoadClubs;