
import {Link} from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import mercado_4 from '../../images/mercado-4.jpg';
import LoadMarkets from './LoadMarkets';
import LoadPosts from './LoadPosts';

function LoadBusineses() {
    let mercadouser=null;
    if(localStorage.getItem('mercadouser')===null){
        mercadouser=false
    }
    else{
        mercadouser=JSON.parse(localStorage.getItem('mercadouser'))
    }

    const [businesses,SetBusinesses]=useState([]);
    const [marketplace,setMarketPlace]=useState('');
    const [adverts,setAdverts]=useState('');
    
    useEffect(()=>{
        let success=true;
        const getYourProducts = async (e) => {
            axios({
                method:'GET',
                url:process.env.REACT_APP_PHP_URL+"loadusers.php",
                params:{
                    owner:mercadouser[0]['id'],
                },
                headers:{
                    'content-type':'application/json',
                }
            })
            .then(response=>{
                if (success) {
                    if(response.data.response){
                        SetBusinesses(response.data.users);
                    }
                    else{
                        SetBusinesses([]);
                    }
                }
            })
            .catch(error=>{
                SetBusinesses([]);
            })
        };
        getYourProducts();

        return ()=>{
            success=false;
        }

    },[businesses])

    const onDelBusinessOwner = (business) => {
        let frmdata=new FormData();
        frmdata.append('id',business.id);
        axios.post(process.env.REACT_APP_PHP_URL+"deleteaccount.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }

  return (
    <>
    {marketplace==='' && adverts==='' &&
        <section id="visitor" class="visitor">
            {businesses && businesses.map((business,key) => (
                <>
                    {business.role==="Owner" &&
                    <div class="get-started" key={key}>
                        <h2 class="started-title">{business.businessname}</h2>
                        <div class="visitor-illustration">
                            <img src={process.env.REACT_APP_PHP_URL+business.uploadedphoto}/>
                            <p>{business.aboutbusiness} </p>
                        </div>
                        <p>Owner: {business.fname +" "+business.oname} </p>
                        <div class="visit-apartment">
                            <button className='btn' onClick={() => setMarketPlace(business.id)}>Products</button>
                            <button className='btn' onClick={() => setAdverts(business.id)}>Adverts</button>
                            {mercadouser[0] && mercadouser[0]['role']!=='Student' &&
                                <button class="btn" onClick={() => onDelBusinessOwner(business)}>Delete Busines </button>
                            }
                            {/* <Link to="/student/chats">Chat</Link> */}
                        </div>
                    </div>  
                    }
                </>
                                                
            ))} 
        </section>  
    }

    {marketplace!=='' &&
        <>
            <button className='btn' onClick={() => setMarketPlace('')}>Back to Businesses</button>
            <LoadMarkets ownerid={marketplace} />
            <button className='btn' onClick={() => setMarketPlace('')}>Back to Businesses</button>
        </>
    } 


    {adverts!=='' &&
        <>
            <button className='btn' onClick={() => setAdverts('')}>Back to Businesses</button>
            <LoadPosts ownerid={adverts} />
            <button className='btn' onClick={() => setAdverts('')}>Back to Businesses</button>
        </>
    }   


        
    </>
    
    
  );
}

export default LoadBusineses;