import {Link, Navigate} from 'react-router-dom';

import { useState } from 'react';
import { useLayoutEffect } from 'react';


function DashSchoolMenu({active}) {
  const [smallmenu,setSmallMenu]=useState(false);
  const [redirect,setRedirect]=useState(false);

  let mercadouser=null;
    if(localStorage.getItem('mercadouser')===null){
        mercadouser=false
    }
    else{
        mercadouser=JSON.parse(localStorage.getItem('mercadouser'))
    }


    useLayoutEffect(() => {
        getloggeduser()
    }, [mercadouser]);
  
    const getloggeduser = () =>{
        if(mercadouser){
            if(mercadouser[0]['role']==="Admin"){
                setRedirect(false)
            }
            else{
                setRedirect(true)
            }
        }
        else{
            setRedirect(true)
        }

    }


    if(redirect){
        return <Navigate to='/login' />
    }
    return (
      <div className="mainmenu">
        <div className="logo">
            <Link to="/admin/admin">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path fill-rule="evenodd" d="M11.622 1.602a.75.75 0 01.756 0l2.25 1.313a.75.75 0 01-.756 1.295L12 3.118 10.128 4.21a.75.75 0 11-.756-1.295l2.25-1.313zM5.898 5.81a.75.75 0 01-.27 1.025l-1.14.665 1.14.665a.75.75 0 11-.756 1.295L3.75 8.806v.944a.75.75 0 01-1.5 0V7.5a.75.75 0 01.372-.648l2.25-1.312a.75.75 0 011.026.27zm12.204 0a.75.75 0 011.026-.27l2.25 1.312a.75.75 0 01.372.648v2.25a.75.75 0 01-1.5 0v-.944l-1.122.654a.75.75 0 11-.756-1.295l1.14-.665-1.14-.665a.75.75 0 01-.27-1.025zm-9 5.25a.75.75 0 011.026-.27L12 11.882l1.872-1.092a.75.75 0 11.756 1.295l-1.878 1.096V15a.75.75 0 01-1.5 0v-1.82l-1.878-1.095a.75.75 0 01-.27-1.025zM3 13.5a.75.75 0 01.75.75v1.82l1.878 1.095a.75.75 0 11-.756 1.295l-2.25-1.312a.75.75 0 01-.372-.648v-2.25A.75.75 0 013 13.5zm18 0a.75.75 0 01.75.75v2.25a.75.75 0 01-.372.648l-2.25 1.312a.75.75 0 11-.756-1.295l1.878-1.096V14.25a.75.75 0 01.75-.75zm-9 5.25a.75.75 0 01.75.75v.944l1.122-.654a.75.75 0 11.756 1.295l-2.25 1.313a.75.75 0 01-.756 0l-2.25-1.313a.75.75 0 11.756-1.295l1.122.654V19.5a.75.75 0 01.75-.75z" clip-rule="evenodd" />
                </svg>
                <span>Mercado Escolar</span>
            </Link>
        </div>
        <div className="menus">
            <Link className={`${active==='home'?"active":""}`} to="/admin/admin">Dash</Link>
            <Link className={`${active==='business'?"active":""}`} to="/admin/businesses">Businesses</Link>
            <Link className={`${active==='students'?"active":""}`} to="/admin/profiles">Students</Link>
            <Link className={`${active==='admins'?"active":""}`} to="/admin/schooladmins">Admins</Link>
            <Link className={`${active==='posts'?"active":""}`} to="/admin/posts">Posts</Link>
            <Link className={`${active==='club'?"active":""}`} to="/admin/clubs">Clubs</Link>
            <Link className={`${active==='chat'?"active":""}`} to="/admin/chats">Chat</Link>
        </div>

        <div className="login">
            <Link className={`${active==='profile'?"active":""}`} to="/admin/profile">{mercadouser[0] && mercadouser[0]['fname'] +" "+mercadouser[0]['oname']}</Link>
            <Link to="../login">Logout</Link>
        </div>

        <div className={`menu ${smallmenu?"active":""}`} onClick={e => (setSmallMenu(!smallmenu))} id="menu">
            <Link to="#">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
            </Link>
        </div>

        <div className={`menu-close ${smallmenu?"":"active"}`} onClick={e => (setSmallMenu(!smallmenu))} id="menu-close">
            <Link to="#">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </Link>
        </div>

        <div className={`menus-small ${smallmenu?"active":""}`} id="menu-small">
            <Link className={`${active==='home'?"active":""}`} to="/admin/admin">Dashboard</Link>
            <Link className={`${active==='business'?"active":""}`} to="/admin/businesses">Businesses</Link>
            <Link className={`${active==='students'?"active":""}`} to="/admin/profiles">Students</Link>
            <Link className={`${active==='admins'?"active":""}`} to="/admin/schooladmins">Admins</Link>
            <Link className={`${active==='posts'?"active":""}`} to="/admin/posts">Posts</Link>
            <Link className={`${active==='club'?"active":""}`} to="/admin/clubs">Clubs</Link>
            <Link className={`${active==='chat'?"active":""}`} to="/admin/chats">Chat</Link>
        </div>
      </div>
    );
  }
  
  export default DashSchoolMenu;
  