
import {Link} from 'react-router-dom';
import DashAdminMenu from "./DashAdminMenu";
import DashAdminFooter from "./DashAdminFooter";

import mercado_garden_3 from '../../images/mercado-garden-3.jpg';
import mercado_pool_3 from '../../images/mercado-pool-3.jpg';
import mercado_plants_2 from '../../images/mercado-plants-2.jpg';
import mercado_pool_1 from '../../images/mercado-pool-1.jpg';
import mercado_garden_5 from '../../images/mercado-garden-5.jpg';
import mercado_6 from '../../images/mercado-6.jpg';

import mercado_4 from '../../images/mercado-4.jpg';
import mercado_elec_2 from '../../images/mercado-elec-2.jpeg';
import mercado_garden_2 from '../../images/mercado-garden-2.jpg';
import avatar from '../../images/avatar.png';
import avatar3 from '../../images/avatar3.png';
import LoadSchoolAdmins from '../reusable/LoadSchoolAdmins';
import AddSchoolAdmin from '../reusable/AddSchoolAdmin';



function AdminSchoolAdmins() {
    document.title='Mecado Escolar Admin Students';
  return (
    <div id="wrapper">
      
      {/* Dashboard Nav Here */}
      <DashAdminMenu active='admins' />
        <div class="content-page">

            <h2 class="section-title">View School Admins Profles
            <div>
                <form>
                    <div class="inputs">
                        <input type="text" placeholder="Search School Admins Profile" />
                        <button class="btn">
                            <span class="material-symbols-rounded">
                            search
                            </span>
                        </button>
                        </div>
                        <button className='btn'>Add New</button>
                    </form>
                </div>
            </h2>

            <AddSchoolAdmin />

            <LoadSchoolAdmins ownerid='' />
            {/* <section id="visitor" class="visitor">
            <div class="get-started">
                
                <div class="visitor-illustration">
                    <img src={avatar}/>
                    <p>School Admin One </p>
                </div>

                <div class="visit-apartment">
                    <Link to="#">Schools</Link>
                    <button className='btn'>Delete</button>
                    <button className='btn'>Edit</button>
                </div>
            </div>

            <div class="get-started">
                
                <div class="visitor-illustration">
                    <img src={avatar3}/>
                    <p>School Admin  Sjj</p>
                </div>
                
                <div class="visit-apartment">
                    <Link to="#">Schools</Link>
                    <button className='btn'>Delete</button>
                    <button className='btn'>Edit</button>
                </div>
            </div>

            <div class="get-started">
            
                <div class="visitor-illustration">
                    <img src={avatar3}/>
                    <p>School Admin thet</p>
                </div>
                
                <div class="visit-apartment">
                    <Link to="#">Schools</Link>
                    <button className='btn'>Delete</button>
                    <button className='btn'>Edit</button>
                </div>
            </div>
            
            </section>

            <section id="visitor" class="visitor">
            <div class="get-started">
                
                <div class="visitor-illustration">
                    <img src={avatar}/>
                    <p>School Admin girlsy </p>
                </div>

                <div class="visit-apartment">
                    <Link to="#">Schools</Link>
                    <button className='btn'>Delete</button>
                    <button className='btn'>Edit</button>
                </div>
            </div>


            <div class="get-started">
                
                <div class="visitor-illustration">
                    <img src={avatar3}/>
                    <p>School Admin Est</p>
                </div>
                
                <div class="visit-apartment">
                <Link to="#">Schools</Link>
                <button className='btn'>Delete</button>
                <button className='btn'>Edit</button>
                </div>
            </div>

            <div class="get-started">
            
                <div class="visitor-illustration">
                    <img src={avatar}/>
                    <p>School Admin Us </p>
                </div>
                
                <div class="visit-apartment">
                    <Link to="#">Schools</Link>
                    <button className='btn'>Delete</button>
                    <button className='btn'>Edit</button>
                </div>
            </div>

            </section>
             */}


        </div>
        <DashAdminFooter />

    </div>
  );
}

export default AdminSchoolAdmins;
