import { useState,useEffect } from 'react';

import Mainmenu from "./Mainmenu";
import Footer from "./Footer";

import mercado_plants_2 from '../images/mercado-plants-2.jpg';
import mercado_build_1 from '../images/mercado-build-1.jpg';

function LoadComments({post_id}) {
  document.title='Blog Posts - Mecado Escolar';
    let wp_post_comments_URL='http://localhost/mercadoescolar/blog/index.php/wp-json/wp/v2/comments?post='+post_id;
    const [posts,setPosts]=useState(null);
    useEffect(() =>{
        async function loadPosts(){
            const response=await fetch(wp_post_comments_URL);
            if(!response.ok){
                return;
            }
            const post=await response.json();
            console.log(post)
            setPosts(post);
        }
        loadPosts();
    },[posts]);

    console.log(posts)
    var postcomments=0;
    if(posts){
        postcomments=(Object.keys(posts).length);
    }

    return postcomments;
}

export default LoadComments;
