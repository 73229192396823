
import {Link} from 'react-router-dom';
import DashboardMenu from "./DashboardMenu";
import DashboardFooter from "./DashboardFooter";

import AddProduct from '../reusable/AddProduct';
import AddPost from '../reusable/AddPost';
import AddClub from '../reusable/AddClub';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';

function Student() {
    document.title='Mecado Escolar Student';
    let mercadouser=null;
    if(localStorage.getItem('mercadouser')===null){
        mercadouser=false
    }
    else{
        mercadouser=JSON.parse(localStorage.getItem('mercadouser'))
    }

    const [products,setProducts]=useState([]);
    const [clubs,setClubs]=useState([]);
    const [posts,setPosts]=useState([]);
    const [orders,setOrders]=useState([]);

    useEffect(()=>{
        let success=true;
        const getYourProducts = async (e) => {
            axios({
                method:'GET',
                url:process.env.REACT_APP_PHP_URL+"loadproducts.php",
                params:{
                    owner:mercadouser[0]['id'],
                },
                headers:{
                    'content-type':'application/json',
                }
            })
            .then(response=>{
                if (success) {
                    if(response.data.response){
                        setProducts(response.data.products);
                    }
                    else{
                        setProducts([]);
                    }
                }
            })
            .catch(error=>{
                setProducts([]);
            })
        };
        getYourProducts();

        return ()=>{
            success=false;
        }

    },[products])


    useEffect(()=>{
        let success=true;
        const getYourClubs = async (e) => {
            axios({
                method:'GET',
                url:process.env.REACT_APP_PHP_URL+"loadclubs.php",
                params:{
                    owner:mercadouser[0]['id'],
                },
                headers:{
                    'content-type':'application/json',
                }
            })
            .then(response=>{
                if (success) {
                    if(response.data.response){
                        setClubs(response.data.clubs);
                    }
                    else{
                        setClubs([]);
                    }
                }
            })
            .catch(error=>{
                setClubs([]);
            })
        };
        getYourClubs();

        return ()=>{
            success=false;
        }

    },[clubs])


    useEffect(()=>{
        let success=true;
        const getYourPosts = async (e) => {
            axios({
                method:'GET',
                url:process.env.REACT_APP_PHP_URL+"loadposts.php",
                params:{
                    creator:mercadouser[0]['id'],
                },
                headers:{
                    'content-type':'application/json',
                }
            })
            .then(response=>{
                if (success) {
                    if(response.data.response){
                        setPosts(response.data.posts);
                    }
                    else{
                        setPosts([]);
                    }
                }
            })
            .catch(error=>{
                setPosts([]);
            })
        };
        getYourPosts();

        return ()=>{
            success=false;
        }

    },[posts])

    useEffect(()=>{
        let success=true;
        const getYourOrders = async (e) => {
            axios({
                method:'GET',
                url:process.env.REACT_APP_PHP_URL+"loadorders.php",
                params:{
                    buyer:mercadouser[0]['id'],
                },
                headers:{
                    'content-type':'application/json',
                }
            })
            .then(response=>{
                if (success) {
                    if(response.data.response){
                        setOrders(response.data.orders);
                    }
                    else{
                        setOrders([]);
                    }
                }
            })
            .catch(error=>{
                setOrders([]);
            })
        };
        getYourOrders();

        return ()=>{
            success=false;
        }

    },[orders])

    const onDelProduct = (product) => {
        let frmdata=new FormData();
        frmdata.append('id',product.id);
        axios.post(process.env.REACT_APP_PHP_URL+"deleteproducts.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }


    const onDelPost = (post) => {
        let frmdata=new FormData();
        frmdata.append('id',post.id);
        axios.post(process.env.REACT_APP_PHP_URL+"deletepost.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }


    const onDelClub = (club) => {
        let frmdata=new FormData();
        frmdata.append('id',club.id);
        axios.post(process.env.REACT_APP_PHP_URL+"deleteclub.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }

    const onCancelOrder = (order) => {
        let frmdata=new FormData();
        frmdata.append('order',order.id);
        frmdata.append('update',"Cancelled");
        axios.post(process.env.REACT_APP_PHP_URL+"updateorder.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }

    const onReturnOrder = (order) => {
        let frmdata=new FormData();
        frmdata.append('order',order.id);
        frmdata.append('update',"Returned");
        axios.post(process.env.REACT_APP_PHP_URL+"updateorder.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }

  return (
    <div id="wrapper">
      
      {/* Dashboard Nav Here */}
      <DashboardMenu active='home' />
        <div className="content-page">
            <h2 className="section-title">
                Student Dashboard
                <p> Welcome ({mercadouser[0] && mercadouser[0]['fname'] +" "+mercadouser[0]['oname']})</p>
            </h2>
            <section id="register" className="register">
                <div className="get-started">
                <h2 className="">
                    <Link className="visitor-btn" to="/student/marketplace">
                    View Market Place
                    </Link>
                    Your Orders
                </h2>
                    <table>
                        <thead>
                            <th>#</th>
                            <th>Order</th>
                            <th>Total</th>
                            <th>Status</th>
                            <th>Action</th>
                        </thead>
                        <tbody>

                            {orders && orders.map((order,key) => (
                                <tr>
                                    <td>{key+1}</td>
                                    <td>ORD{order.id}</td>
                                    <td>${order.price * order.quantity}</td>
                                    <td>{order.status}</td>
                                    <td>
                                        <button>View</button>
                                        {order.status==="Pending" &&
                                            <button onClick={() => onCancelOrder(order)}>Cancel</button>
                                        }

                                        {order.status==="Delivered" &&
                                            <button onClick={() => onReturnOrder(order)}>Return</button>
                                        }
                                        
                                    </td>
                                </tr>
                                                                                
                            ))} 

                            
                        </tbody>
                    </table>
                </div>

                <div className="get-started">
                <h2 className="">
                    <Link className="visitor-btn" to="/student/posts">
                        View Posts
                    </Link>
                    Your Posts
                </h2>
                    <table>
                        <thead>
                            <th>#</th>
                            <th>Post</th>
                            <th>Time</th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                            {posts && posts.map((post,key) => (
                                <tr>
                                    <td>{key+1}</td>
                                    <td>{post.postinformation}</td>
                                    <td>{post.created_at}</td>
                                    <td>
                                    <button>View</button>
                                        <button onClick={() => onDelPost(post)}>Delete</button>
                                    </td>
                                </tr>
                                                                                
                            ))} 
                            
                        </tbody>
                    </table>
                </div>


                <div className="get-started">
                    <h2 className="">
                        <Link className="visitor-btn" to="/student/clubs">
                            View Clubs
                        </Link>
                        Your clubs
                    </h2>
                        <table>
                            <thead>
                                <th>#</th>
                                <th>Club</th>
                                <th>Members</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                            {clubs && clubs.map((club,key) => (
                                <tr>
                                    <td>{key+1}</td>
                                    <td>{club.clubname}</td>
                                    <td>{club.members}</td>
                                    <td>
                                    <button>View</button>
                                        <button onClick={() => onDelClub(club)}>Delete</button>
                                    </td>
                                </tr>
                                                                                
                            ))}  
                                
                            </tbody>
                        </table>
                    </div>

            </section>

            <section id="register" className="register">
                <div className="get-started ">
                <h5 className="">
                    Add Your Products
                </h5>
                <AddProduct />

                <h3>Your Products</h3>
                <table>
                    <thead>
                        <th>#</th>
                        <th>Product</th>
                        <th>Stock</th>
                        <th>Price</th>
                        <th>Sold</th>
                        <th>Action</th>
                    </thead>
                    <tbody>
                    {products && products.map((product,key) => (
                        <tr>
                            <td>{key+1}</td>
                            <td>{product.productname}</td>
                            <td>{product.productstock}</td>
                            <td>${product.productprice}</td>
                            <td>{product.sold}</td>
                            <td>
                                <button>purchases</button>
                                <button>Edit</button>
                                <button onClick={() => onDelProduct(product)}>Delete</button>
                            </td>
                        </tr>
                                                                        
                    ))}  
                        
                    </tbody>
                </table>
                
                </div>

                <div className="get-started">
                    <h5 className="">
                        Create Post
                    </h5>
                    <AddPost />
                </div>


                <div className="get-started">
                    <h5 className="">
                        Create Club
                    </h5>
                    <AddClub />
                </div>

            </section>
        </div>
        <DashboardFooter />

    </div>
  );
}

export default Student;
