
import {Link} from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import mercado_4 from '../../images/mercado-4.jpg';

function LoadPosts({ownerid}) {
    let mercadouser=null;
    if(localStorage.getItem('mercadouser')===null){
        mercadouser=false
    }
    else{
        mercadouser=JSON.parse(localStorage.getItem('mercadouser'))
    }

    const [posts,setPosts]=useState([]);
    
    useEffect(()=>{
        let success=true;
        let load='';
        if(ownerid===''){
            load="loadallposts.php";
        }
        else{
            load="loadposts.php";
        }

        const getPosts = async (e) => {
            axios({
                method:'GET',
                url:process.env.REACT_APP_PHP_URL+load,
                params:{
                    creator:(ownerid==="")?mercadouser[0]['id']:ownerid,
                },
                headers:{
                    'content-type':'application/json',
                }
            })
            .then(response=>{
                if (success) {
                    if(response.data.response){
                        setPosts(response.data.posts);
                    }
                    else{
                        setPosts([]);
                    }
                }
            })
            .catch(error=>{
                setPosts([]);
            })
        };
        getPosts();

        return ()=>{
            success=false;
        }

    },[posts])


    const onOrder = (product) => {
        let frmdata=new FormData();
        frmdata.append('product',product.id);
        frmdata.append('seller',product.owner);
        frmdata.append('price',product.productprice);
        frmdata.append('quantity',product.qty);
        frmdata.append('buyer',mercadouser[0]['id']);
        axios.post(process.env.REACT_APP_PHP_URL+"savecartitem.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }

    const onDelete = (post) => {
        let frmdata=new FormData();
        frmdata.append('id',post.id);
        frmdata.append('buyer',mercadouser[0]['id']);
        axios.post(process.env.REACT_APP_PHP_URL+"deletepost.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }
    

  return (
    <>
    <section id="visitor" class="visitor">
    {posts && posts.map((post,key) => (
        <>
            {mercadouser[0]['role']==="Student" &&  post.creator!==mercadouser[0]['id'] &&
                <div class="get-started" key={key}>
                        <p>{post.creatorname}. </p>
                    <div class="visitor-illustration">
                        <img src={process.env.REACT_APP_PHP_URL+post.uploadedphoto}/>
                        <p>{post.posttype}. </p>
                    </div>

                    <div>
                        <h6>Hidden Plants</h6>
                        <p>{post.postinformation}. </p>
                    </div>

                    <div class="visit-apartment">
                        <a href="">Comments <span class="comments">3</span></a>
                        <button class="btn">View</button>
                        
                    </div>
                </div>
            }

            {mercadouser[0]['role']!=="Student" &&
                <div class="get-started" key={key}>
                    <p>{post.creatorname}. </p>
                    <div class="visitor-illustration">
                        <img src={process.env.REACT_APP_PHP_URL+post.uploadedphoto}/>
                        <p>{post.posttype}. </p>
                    </div>
                    
                    <div>
                        <h6>{post.businessname}</h6>
                        <p>{post.postinformation}. </p>
                    </div>

                    <div class="visit-apartment">
                        <button type='button' className="btn" onClick={() => onDelete(post)}>Delete</button>
                    </div>
                </div>
            }
        </>
                                           
    ))} 
        
    </section>  


        
    </>
    
    
  );
}

export default LoadPosts;