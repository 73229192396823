
import {Link} from 'react-router-dom';
import DashAdminMenu from "./DashAdminMenu";
import DashAdminFooter from "./DashAdminFooter";

import mercado_garden_3 from '../../images/mercado-garden-3.jpg';
import mercado_pool_2 from '../../images/mercado-pool-2.jpg';
import mercado_plants_2 from '../../images/mercado-plants-2.jpg';
import mercado_pool_1 from '../../images/mercado-pool-1.jpg';
import mercado_garden_5 from '../../images/mercado-garden-5.jpg';
import mercado_6 from '../../images/mercado-6.jpg';
import community_2 from '../../videos/lunamer-community-2.mp4';
import community_1 from '../../videos/lunamer-community-1.mp4';

function AdminsAdverts() {
    document.title='Mecado Escolar Super Admin';
  return (
    <div id="wrapper">
      
      {/* Dashboard Nav Here */}
      <DashAdminMenu active='adverts' />
        <div className="content-page">
            <h2 className="section-title">View Adverts and promotions
                <div>
                    <form>
                        <div className="inputs">
                            <input type="text" placeholder="Search Adverts" />
                            <button className="btn">
                                <span className="material-symbols-rounded">
                                search
                                </span>
                            </button>
                            </div>
                        </form>
                    </div>
            </h2>
            
            <section id="visitor" className="visitor">
                <div className="get-started">
                    
                    <div className="visitor-illustration">
                        <video src={community_2} controls> </video>
                    </div>

                    <div>
                        <h6>KLL Business One</h6>
                        <p>Have you ever been to the bypass, there is more than nature there. </p>
                    </div>

                    <div className="visit-apartment">
                        <a href="">Comments <span className="comments">3</span></a>
                        <button className="btn">View</button>
                        <button className="btn">Delete</button>
                        
                    </div>
                </div>

                <div className="get-started">
                    
                    <div className="visitor-illustration">
                        <video src={community_1} controls> </video>
                    </div>

                    <div>
                        <h6>KLL Business One</h6>
                        <p>This week, our club decided to give us a guided tour about properties. </p>
                    </div>

                    <div className="visit-apartment">
                        <a href="">Comments <span className="comments">10</span></a>
                        <button className="btn">View</button>
                        <button className="btn">Delete</button>
                        
                    </div>
                </div>

                <div className="get-started">
                    
                    <div className="visitor-illustration">
                        <img src={mercado_pool_2}/>
                        <p>Decided to Swim</p>
                    </div>

                    <div>
                        <h6>KLL Business One</h6>
                        <p>Today with my family, we devided to give Lunamar waters a try. </p>
                    </div>

                    <div className="visit-apartment">
                        <a href="">Comments <span className="comments">0</span></a>
                        <button className="btn">View</button>
                        <button className="btn">Delete</button>
                        
                    </div>
                </div>
            </section>
        </div>
        <DashAdminFooter />

    </div>
  );
}

export default AdminsAdverts;
