
import {Link} from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import avatar from '../../images/avatar3.png';
import LoadMarkets from './LoadMarkets';
import LoadPosts from './LoadPosts';
import LoadClubs from './LoadClubs';

function LoadSchoolAdmins() {
    let mercadouser=null;
    if(localStorage.getItem('mercadouser')===null){
        mercadouser=false
    }
    else{
        mercadouser=JSON.parse(localStorage.getItem('mercadouser'))
    }

    const [students,setStudents]=useState([]);
    const [marketplace,setMarketPlace]=useState('');
    const [posts,setPosts]=useState('');
    const [clubs,setClubs]=useState('');
    
    useEffect(()=>{
        let success=true;
        const getSchoolAdminsProfiles = async (e) => {
            axios({
                method:'GET',
                url:process.env.REACT_APP_PHP_URL+"loadusers.php",
                params:{
                    owner:mercadouser[0]['id'],
                },
                headers:{
                    'content-type':'application/json',
                }
            })
            .then(response=>{
                if (success) {
                    if(response.data.response){
                        setStudents(response.data.users);
                    }
                    else{
                        setStudents([]);
                    }
                }
            })
            .catch(error=>{
                setStudents([]);
            })
        };
        getSchoolAdminsProfiles();

        return ()=>{
            success=false;
        }

    },[students])

    const onDelSchoolAdmin = (student) => {
        let frmdata=new FormData();
        frmdata.append('id',student.id);
        axios.post(process.env.REACT_APP_PHP_URL+"deleteaccount.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }
    

  return (
    <>
    {marketplace==='' && posts==='' && clubs==='' &&
        <section id="visitor" class="visitor">
            {students && students.map((student,key) => (
                <>
                    {student.role==="School" && student.id!==mercadouser[0]['id'] &&
                    <div class="get-started" key={key}>
                    
                        <div class="visitor-illustration">
                            <img src={avatar}/>
                            <p>Name: {student.fname +" "+student.mname+" "+student.oname} </p>
                        </div>
                        <p>School: {student.school} </p>
                        <div class="visit-apartment">
                            {/* <button className='btn' onClick={() => setMarketPlace(student.id)}>Products</button>
                            <button className='btn' onClick={() => setPosts(student.id)}>Posts</button>
                            <button className='btn' onClick={() => setClubs(student.id)}>Clubs</button> */}
                            {mercadouser[0] && mercadouser[0]['role']!=='Student' &&
                                <button class="btn" onClick={() => onDelSchoolAdmin(student)}>Delete </button>
                            }
                            {/* <Link to="/student/chats">Chat</Link> */}
                        </div>
                    </div>
                    }
                </>
                                                
            ))} 
        </section>  
    }

    {marketplace!=='' &&
        <>
            <button className='btn' onClick={() => setMarketPlace('')}>Back to Students</button>
            <LoadMarkets ownerid={marketplace} />
            <button className='btn' onClick={() => setMarketPlace('')}>Back to Students</button>
        </>
    }   


    {posts!=='' &&
        <>
            <button className='btn' onClick={() => setPosts('')}>Back to Students</button>
            <LoadPosts ownerid={posts} />
            <button className='btn' onClick={() => setPosts('')}>Back to Students</button>
        </>
    }

    {clubs!=='' &&
        <>
            <button className='btn' onClick={() => setClubs('')}>Back to Students</button>
            <LoadClubs ownerid={clubs} />
            <button className='btn' onClick={() => setClubs('')}>Back to Students</button>
        </>
    }   


        
    </>
    
    
  );
}

export default LoadSchoolAdmins;