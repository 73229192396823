import Mainmenu from "./Mainmenu";
import {Link, Navigate} from 'react-router-dom';
import { useState } from "react";
import axios from "axios";

function Login() {
    document.title='Login - Mecado Escolar';

    const [email,setEmail]=useState('');
    const [password,setPassword]=useState('');
    const [redirect,setRedirect]=useState(false);

    const logintoAccount =(e) =>{
        e.preventDefault();
        if(email.trim()===""){
            alert("Email is Missing")
            return
        }
        
        else if(email.indexOf('@') <2 ){
            alert("Email looks too smaller");
            return
        }
        else if(password.trim()===""){
            alert("Password is Missing")
            return
        }

        else if(password.trim().length<7){
            alert("Password should be more than 7 characters")
            return
        }

        let frmdata=new FormData();
        frmdata.append('email',email);
        frmdata.append('password',password);
        
        axios.post(process.env.REACT_APP_PHP_URL+"login.php",frmdata,null)
        .then(response=>{
            if(response.data.response){
                if (response.data.users[0]['role'] === 'Student') {
                    localStorage.setItem('mercadouser',JSON.stringify(response.data.users));
                    setRedirect('/student/student');
                }
                else if (response.data.users[0]['role'] === 'Owner') {
                    localStorage.setItem('mercadouser',JSON.stringify(response.data.users));
                    setRedirect('/business/owner');
                }
                else if (response.data.users[0]['role'] === 'School') {
                    localStorage.setItem('mercadouser',JSON.stringify(response.data.users));
                    setRedirect('/school/admin');
                }
                else if (response.data.users[0]['role'] === 'Admin') {
                    localStorage.setItem('mercadouser',JSON.stringify(response.data.users));
                    setRedirect('/admin/admin');
                }
                else{
                    alert("You are Not Authorised to this Role");
                }  
            }
            else{
                alert(response.data)
            }
            
        })
        .catch(error=>{
            alert(''+error)
        })
    }


    if(redirect){
        return <Navigate to={redirect} />
    }
    else{
        localStorage.removeItem('mercadouser');
    }
  return (
    <div id="wrapper">
        {/* Main Menu */}
        <Mainmenu active='login' />

            <div class="content-page">
                <h2 class="section-title">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                    </svg>
                    Restricted Area
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                    </svg> 
                </h2>
                <section id="contactus" class="contactus">
                    <div>
                    <p>Enter your Login Credentials</p>
                    <form onSubmit={logintoAccount}>
                        <div className="inputs">
                            <label>Email</label>
                            <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" />
                        </div>
                        <div className="inputs">
                            <label>Password</label>
                            <input type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
                        </div>

                        <button class="btn">Login</button>
                        <Link to="/forgot-password">Forgot Password</Link>

                        <div class="inputs">
                        <p>User Role direct Link access</p>
                        <p><Link to="/admin/admin">Super Admin</Link></p>
                        <p><Link to="/business/owner">Business Owner</Link></p>
                        <p><Link to="/school/admin">School Admin</Link></p>
                        <p><Link to="/student/student">Student</Link></p>
                        </div>
                        
                    </form>
                    </div>
                </section>
            </div>
      
        {/* Footer */}

    </div>
  );
}

export default Login;
