
import {Link} from 'react-router-dom';
import DashSchoolMenu from "./DashSchoolMenu";
import DashSchoolFooter from "./DashSchoolFooter";

import mercado_garden_3 from '../../images/mercado-garden-3.jpg';
import mercado_pool_3 from '../../images/mercado-pool-3.jpg';
import mercado_plants_2 from '../../images/mercado-plants-2.jpg';
import mercado_pool_1 from '../../images/mercado-pool-1.jpg';
import mercado_garden_5 from '../../images/mercado-garden-5.jpg';
import avatar from '../../images/avatar.png';
import mercado_6 from '../../images/mercado-6.jpg';

function SchoolProfile() {
    document.title='Mecado Escolar Profile';
  return (
    <div id="wrapper">
      
      {/* Dashboard Nav Here */}
      <DashSchoolMenu active='profile' />
        <div className="content-page">
            <h2 className="section-title">
                View and Update your Profile
                <p>Make Changes to your profile by updating Photo, Name, Phone and Password</p>
            </h2>
            <section id="visitor" className="visitor">
                <div className="get-started">
                    <h2 className="started-title">Profile Photo</h2>
                    <div className="visitor-illustration-profile">
                        <img src={avatar}/>
                        <form>
                            <div className="inputs">
                                <input type="file" id="profilephoto" name="profilephoto" required />
                                <button className="btn">Upload Photo 
                                
                                </button>
                            </div>

                            
                        <div className="inputs-profile">
                            <input type="email" id="email" name="email" placeholder="Email:schooladmin@gmail.com" disabled required />
                        </div>

                        <div className="inputs-profile">
                            <input type="text" id="ownerid" name="email" placeholder="ID:46465" disabled required />
                        </div>

                        </form>
                    </div>

                </div>

                <div className="get-started">
                    <h2 className="started-title">Update Profile Details</h2>
                    <div>
                    <form>

                    <div className="inputs-profile">
                        <label>First Name</label>
                        <input type="text" id="sname" name="sname" placeholder="First Name" />
                        </div>
        
                        <div className="inputs-profile">
                        <label>Middle Name</label>
                        <input type="text" id="oname" name="oname" placeholder="Middle Name" />
                        </div>
        
                        <div className="inputs-profile">
                        <label>Other Name</label>
                        <input type="text" id="oname" name="oname" placeholder="Other Name" />
                        </div>
        
                        <div className="inputs-profile">
                        <label>Phone</label>
                        <input type="tel" id="phone" name="phone" placeholder="Your Phone Number" required />
                        </div>
                        

                        <div className="inputs-profile">
                            <label>Your Address</label>
                            <textarea id="address" name="address" rows="5" placeholder="Address and Location"></textarea>
                        </div>
                        
        
                        <button className="btn">Update Profile</button>
        
                    </form>
                    </div>
                </div>

                <div className="get-started">
                    <h2 className="started-title">Change Password</h2>
                    <div>
                    <form>
                        <div className="inputs-profile">
                            <label>Password</label>
                            <input type="password" id="password" name="password" placeholder="Password" required />
                        </div>
        
                        <div className="inputs-profile">
                            <label>Confirm Password</label>
                            <input type="password" id="confirmpassword" name="confirmpassword" placeholder="Confirm Password" required />
                        </div>

                        <button className="btn">Change Password</button>
        
                    </form>
                    </div>
                </div>

            </section>
        </div>
        <DashSchoolFooter />

    </div>
  );
}

export default SchoolProfile;
