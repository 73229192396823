
import {Link} from 'react-router-dom';
import DashSchoolMenu from "./DashSchoolMenu";
import DashSchoolFooter from "./DashSchoolFooter";

import mercado_garden_3 from '../../images/mercado-garden-3.jpg';
import mercado_pool_3 from '../../images/mercado-pool-3.jpg';
import mercado_plants_2 from '../../images/mercado-plants-2.jpg';
import mercado_pool_1 from '../../images/mercado-pool-1.jpg';
import mercado_garden_5 from '../../images/mercado-garden-5.jpg';
import mercado_6 from '../../images/mercado-6.jpg';

import mercado_4 from '../../images/mercado-4.jpg';
import pool_2 from '../../images/mercado-pool-2.jpg';

import community_2 from '../../videos/lunamer-community-2.mp4';
import community_1 from '../../videos/lunamer-community-1.mp4';

import mercado_elec_2 from '../../images/mercado-elec-2.jpeg';
import mercado_garden_2 from '../../images/mercado-garden-2.jpg';
import LoadPosts from '../reusable/LoadPosts';



function SchoolPosts() {
    document.title='Mecado Escolar School Posts';
  return (
    <div id="wrapper">
      
      {/* Dashboard Nav Here */}
        <DashSchoolMenu active='posts' />
            <div class="content-page">
                <h2 class="section-title">View student Posts
                <div>
                    <form>
                        <div class="inputs">
                            <input type="text" placeholder="Search Post" />
                            <button class="btn">
                                <span class="material-symbols-rounded">
                                search
                                </span>
                            </button>
                            </div>
                        </form>
                    </div>
            </h2>
            
            <LoadPosts ownerid='' />
            {/* <section id="visitor" class="visitor">
                <div class="get-started">
                    
                    <div class="visitor-illustration">
                        <video src={community_2} controls> </video>
                    </div>

                    <div>
                        <h6>Hidden Plants</h6>
                        <p>Have you ever been to the bypass, there is more than nature there. </p>
                    </div>

                    <div class="visit-apartment">
                        <a href="">Comments <span class="comments">3</span></a>
                        <button class="btn">View</button>
                        <button class="btn">Delete</button>
                        
                    </div>
                </div>

                <div class="get-started">
                    
                    <div class="visitor-illustration">
                        <video src={community_1} controls> </video>
                    </div>

                    <div>
                        <h6>Was delighted to take a tour at last</h6>
                        <p>This week, our club decided to give us a guided tour about properties. </p>
                    </div>

                    <div class="visit-apartment">
                        <a href="">Comments <span class="comments">10</span></a>
                        <button class="btn">View</button>
                        <button class="btn">Delete</button>
                        
                    </div>
                </div>

                <div class="get-started">
                    
                    <div class="visitor-illustration">
                        <img src={pool_2}/>
                        <p>Decided to Swim</p>
                    </div>

                    <div>
                        <p>Today with my family, we devided to give Lunamar waters a try. </p>
                    </div>

                    <div class="visit-apartment">
                        <a href="">Comments <span class="comments">0</span></a>
                        <button class="btn">View</button>
                        <button class="btn">Delete</button>
                        
                    </div>
                </div>
            </section> */}
            </div>
        <DashSchoolFooter />

    </div>
  );
}

export default SchoolPosts;
