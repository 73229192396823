
import {Link} from 'react-router-dom';
import DashboardMenu from "./DashboardMenu";
import DashboardFooter from "./DashboardFooter";

import mercado_garden_3 from '../../images/mercado-garden-3.jpg';
import mercado_pool_3 from '../../images/mercado-pool-3.jpg';
import mercado_plants_2 from '../../images/mercado-plants-2.jpg';
import mercado_pool_1 from '../../images/mercado-pool-1.jpg';
import mercado_garden_5 from '../../images/mercado-garden-5.jpg';
import mercado_6 from '../../images/mercado-6.jpg';

import mercado_4 from '../../images/mercado-4.jpg';
import mercado_elec_2 from '../../images/mercado-elec-2.jpeg';
import mercado_garden_2 from '../../images/mercado-garden-2.jpg';
import LoadBusineses from '../reusable/LoadBusineses';



function Businesses() {
    document.title='Mecado Escolar Businesses';
  return (
    <div id="wrapper">
      
      {/* Dashboard Nav Here */}
      <DashboardMenu active='business' />
            <div class="content-page">

                <h2 class="section-title">Check All Businesses here
                <div>
                    <form>
                        <div class="inputs">
                            <input type="text" placeholder="Search Business" />
                            <button class="btn">
                                <span class="material-symbols-rounded">
                                search
                                </span>
                            </button>
                            </div>
                        </form>
                    </div>
                </h2>

                <LoadBusineses />
                {/* <section id="visitor" class="visitor">
                    <div class="get-started">
                        <h2 class="started-title">KLL Business One
                        </h2>
                        <div class="visitor-illustration">
                            <img src={mercado_4}/>
                            <p>Light and Shinny Shoes </p>
                        </div>

                        <div class="visit-apartment">
                            <Link to="/student/marketplace">Products</Link>
                            <Link to="/student/chats">Chat</Link>
                        </div>
                    </div>

                    <div class="get-started">
                        <h2 class="started-title">HK Business tgw
                        </h2>
                        <div class="visitor-illustration">
                            <img src={mercado_elec_2}/>
                            <p>Get all your appliances here </p>
                        </div>
                        
                        <div class="visit-apartment">
                            <Link to="/student/marketplace">Products</Link>
                            <Link to="/student/chats">Chat</Link>
                        </div>
                    </div>


                    <div class="get-started">
                        <h2 class="started-title">GGd Business Gfar
                        </h2>
                        <div class="visitor-illustration">
                            <img src={mercado_garden_2}/>
                            <p>Get items for your garden</p>
                        </div>
                        
                        <div class="visit-apartment">
                            <Link to="/student/marketplace">Products</Link>
                            <Link to="/student/chats">Chat</Link>
                        </div>
                    </div>
                
                </section>

                <section id="visitor" class="visitor">
                    <div class="get-started">
                        <h2 class="started-title">KLL Business One
                        </h2>
                        <div class="visitor-illustration">
                            <img src={mercado_4}/>
                            <p>Light and Shinny Shoes </p>
                        </div>

                        <div class="visit-apartment">
                            <Link to="/student/marketplace">Products</Link>
                            <Link to="/student/chats">Chat</Link>
                        </div>
                    </div>

                    <div class="get-started">
                        <h2 class="started-title">HK Business tgw
                        </h2>
                        <div class="visitor-illustration">
                            <img src={mercado_elec_2}/>
                            <p>Get all your appliances here </p>
                        </div>
                        
                        <div class="visit-apartment">
                        <Link to="/student/marketplace">Products</Link>
                        <Link to="/student/chats">Chat</Link>
                        </div>
                    </div>


                    <div class="get-started">
                        <h2 class="started-title">GGd Business Gfar
                        </h2>
                        <div class="visitor-illustration">
                            <img src={mercado_garden_2}/>
                            <p>Get items for your garden</p>
                        </div>
                        
                        <div class="visit-apartment">
                        <Link to="/student/marketplace">Products</Link>
                        <Link to="/student/chats">Chat</Link>
                        </div>
                    </div>

                </section>

                <section id="visitor" class="visitor">
                    <div class="get-started">
                    <h2 class="started-title">KLL Business One
                    </h2>
                    <div class="visitor-illustration">
                        <img src={mercado_4}/>
                        <p>Light and Shinny Shoes </p>
                    </div>

                    <div class="visit-apartment">
                        <Link to="/student/marketplace">Products</Link>
                        <Link to="/student/chats">Chat</Link>
                    </div>
                    </div>

                    <div class="get-started">
                    <h2 class="started-title">HK Business tgw
                    </h2>
                    <div class="visitor-illustration">
                        <img src={mercado_elec_2}/>
                        <p>Get all your appliances here </p>
                    </div>
                    
                    <div class="visit-apartment">
                        <Link to="/student/marketplace">Products</Link>
                        <Link to="/student/chats">Chat</Link>
                    </div>
                    </div>


                    <div class="get-started">
                    <h2 class="started-title">GGd Business Gfar
                    </h2>
                    <div class="visitor-illustration">
                        <img src={mercado_garden_2}/>
                        <p>Get items for your garden</p>
                    </div>
                    
                    <div class="visit-apartment">
                        <Link to="/student/marketplace">Products</Link>
                        <Link to="/student/chats">Chat</Link>
                    </div>
                    </div>

                </section> */}


            </div>
        <DashboardFooter />

    </div>
  );
}

export default Businesses;
