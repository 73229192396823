
import axios from 'axios';
import { useState } from 'react';
import {Link} from 'react-router-dom';

function AddSchoolAdmin() {
    let mercadouser=null;
    if(localStorage.getItem('mercadouser')===null){
        mercadouser=false
    }
    else{
        mercadouser=JSON.parse(localStorage.getItem('mercadouser'))
    }
    
    const [fname,setFname]=useState('');
    const [mname,setMname]=useState('');
    const [oname,setOname]=useState('');
    const [email,setEmail]=useState('');
    const [phone,setPhone]=useState('');
    const [address,setAddress]=useState('');
    const [school,setSchool]=useState('');
    const [userID,setUserID]=useState('');
    const [password,setPassword]=useState('');
    const [confirmpassword,setConfirmPassword]=useState('');

    const saveSchoolAdmin =(e) =>{
        e.preventDefault();

        if(fname.trim()===""){
            alert("First name is Missing")
            return
        }
        else if(fname.length<4){
            alert("First Name needs more than four characters");
            return
        }
        else if(mname.trim()===""){
            alert("Middle name is Missing")
            return
        }
        else if(mname.length<4){
            alert("Middle Name needs more than four characters");
            return
        }

        else if(oname.trim()===""){
            alert("Other name is Missing")
            return
        }
        else if(oname.length<4){
            alert("Other Name needs more than four characters");
            return
        }

        else if(email.trim()===""){
            alert("Email is Missing")
            return
        }
        
        else if(email.indexOf('@') <2 ){
            alert("Email looks too smaller");
            return
        }
        else if(userID.trim()===""){
            alert("User ID is Missing")
            return
        }
        else if(isNaN(userID)){
            alert("Numbers are required for User ID");
            return
        }

        else if(school.trim()===""){
            alert("School is Missing")
            return
        }

        else if(address.trim()===""){
            alert("Address is Missing")
            return
        }

        else if(address.length<8){
            alert("Student Address needs more than eight characters");
            return
        }

        else if(isNaN(phone)){
            alert("Numbers are required for Phone");
            return
        }

        else if(phone.length!==10){
            alert("Phone must be 10 digits");
            return
        }

        else if(password.trim()===""){
            alert("Password is Missing")
            return
        }

        else if(password.trim().length<7){
            alert("Password should be more than 7 characters")
            return
        }

        else if(password.trim()!==confirmpassword.trim()){
            alert("Confirm Password does not match your Password")
            return
        }

        let frmdata=new FormData();
        frmdata.append('fname',fname);
        frmdata.append('mname',mname);
        frmdata.append('oname',oname);
        frmdata.append('phone',phone);
        frmdata.append('email',email);
        frmdata.append('address',address);
        frmdata.append('school',school);
        frmdata.append('userID',userID);
        frmdata.append('password',password);
        
        axios.post(process.env.REACT_APP_PHP_URL+"saveschooladmin.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
        .finally(()=>{
            clearForm()
        })
    }

    const clearForm =() =>{
        setFname('');
        setMname('');
        setOname('');
        setEmail('');
        setPhone('');
        setAddress('');
        setSchool('');
        setUserID('');
        setPassword('');
        setConfirmPassword('');
    }
  return (
    <section id="contactus" className="contactus">
        <div>
        <p>Complete the details below to Register a School Admin</p>
        <form onSubmit={saveSchoolAdmin}>
            <div className="inputs">
            <label>First Name</label>
            <input type="text" id="fname" name="fname" value={fname} onChange={(e) => setFname(e.target.value)} placeholder="First Name" />
            </div>

            <div className="inputs">
            <label>Middle Name</label>
            <input type="text" id="mname" name="mname" value={mname} onChange={(e) => setMname(e.target.value)} placeholder="Middle Name" />
            </div>

            <div className="inputs">
            <label>Other Name</label>
            <input type="text" id="oname" name="oname" value={oname} onChange={(e) => setOname(e.target.value)} placeholder="Other Name" />
            </div>

            <div className="inputs">
            <label>Phone</label>
            <input type="tel" id="phone" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Your Phone Number" required />
            </div>

            <div className="inputs">
            <label>Email</label>
            <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" />
            </div>
            
            <div className="inputs">
                <label>ID</label>
                <input type="tel" id="visitorid" name="visitorid" value={userID} onChange={(e) => setUserID(e.target.value)} placeholder="ID Number" required />
            </div>

            <div className="inputs">
            <label>School Admin Address</label>
            <textarea id="address" name="address" rows="5" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Address and Location"></textarea>
            </div>

            <div className="inputs">
                <label>School</label>
                <select id="school" name="school" value={school} onChange={(e) => setSchool(e.target.value)} required>
                    <option value="">Choose School</option>
                    <option value="one">School One</option>
                    <option value="two">School Two</option>
                    <option value="three">School Three</option>
                    <option value="four">School Four</option>
                </select>
            </div>

            <div className="inputs">
            <label>Password</label>
                <input type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
            </div>

            <div className="inputs">
                <label>Confirm Password</label>
                <input type="password" id="confirmpassword" name="confirmpassword" value={confirmpassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password" required />
            </div>

            <button className="btn">Create School Admin Account</button>

        </form>
        </div>
    </section>
  );
}

export default AddSchoolAdmin;