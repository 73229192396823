
import {Link} from 'react-router-dom';
import DashboardMenu from "./DashboardMenu";
import DashboardFooter from "./DashboardFooter";

import mercado_garden_3 from '../../images/mercado-garden-3.jpg';
import mercado_pool_3 from '../../images/mercado-pool-3.jpg';
import mercado_plants_2 from '../../images/mercado-plants-2.jpg';
import mercado_pool_1 from '../../images/mercado-pool-1.jpg';
import mercado_garden_5 from '../../images/mercado-garden-5.jpg';
import mercado_6 from '../../images/mercado-6.jpg';

import mercado_4 from '../../images/mercado-4.jpg';
import mercado_elec_2 from '../../images/mercado-elec-2.jpeg';
import mercado_garden_2 from '../../images/mercado-garden-2.jpg';
import avatar from '../../images/avatar.png';
import { useState } from 'react';
import axios from 'axios';



function Profile() {
    document.title='Mecado Escolar Profile';

    let mercadouser=null;
    if(localStorage.getItem('mercadouser')===null){
        mercadouser=false
    }
    else{
        mercadouser=JSON.parse(localStorage.getItem('mercadouser'))
    }

    const [firstname, setFirstname] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [othername, setOthername] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');

    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmpassword] = useState('');

    const updatePassword = (e)=>{
        e.preventDefault();

        if(password.trim()===""){
            alert("password is Missing")
        }else if(confirmpassword.trim()==="" || confirmpassword.trim()!==password.trim()){
            alert("Please confirm password")
        }else{
            let frmdata=new FormData();
            frmdata.append('password',password);
            frmdata.append('user',mercadouser[0]['id']);
            
            axios.post(process.env.REACT_APP_PHP_URL+"update-password.php",frmdata,null)
            .then(response=>{
                alert(response.data);
                window.location.reload();
            });
        }
    }

    const updateProfile = (e)=>{
        e.preventDefault();
        let frmdata=new FormData();
        frmdata.append('firstname',firstname);
        frmdata.append('middlename',middlename);
        frmdata.append('othername',othername);
        frmdata.append('phone',phone);
        frmdata.append('address',address);
        frmdata.append('user',mercadouser[0]['id']);
        
        axios.post(process.env.REACT_APP_PHP_URL+"update-profile.php",frmdata,null)
        .then(response=>{
            alert(response.data);
            window.location.reload();
        });
    }

  return (
    <div id="wrapper">
      
      {/* Dashboard Nav Here */}
        <DashboardMenu active='profile' />
            <div className="content-page">
            <h2 className="section-title">
                    View and Update your Profile
                    <p>Make Changes to your profile by updating Photo, Name, Phone and Password</p>
                </h2>
                <section id="visitor" className="visitor">
                    <div className="get-started">
                        <h2 className="started-title">Profile Photo</h2>
                        <div className="visitor-illustration-profile">
                            <img src={avatar}/>
                            <form>
                                <div className="inputs">
                                    <input type="file" id="profilephoto" name="profilephoto" required />
                                    <button className="btn">Upload Photo 
                                    
                                    </button>
                                </div>

                                
                            <div className="inputs-profile">
                                <input type="email" id="email" name="email" placeholder="Email:student@gmail.com" disabled required />
                            </div>

                            <div className="inputs-profile">
                                <input type="text" id="studentid" name="email" placeholder="ID:46465" disabled required />
                            </div>

                            </form>
                        </div>

                    </div>

                    <div className="get-started">
                        <h2 className="started-title">Update Profile Details</h2>
                        <div>
                        <form onSubmit={updateProfile}>

                        <div className="inputs-profile">
                            <label>First Name</label>
                            <input type="text" id="firstname" onChange={(e) => setFirstname(e.target.value)} name="firstname" placeholder="First Name" />
                            </div>
            
                            <div className="inputs-profile">
                            <label>Middle Name</label>
                            <input type="text" id="middlename" onChange={(e) => setMiddlename(e.target.value)} name="middlename" placeholder="Middle Name" />
                            </div>
            
                            <div className="inputs-profile">
                            <label>Other Name</label>
                            <input type="text" id="othername" onChange={(e) => setOthername(e.target.value)} name="othername" placeholder="Other Name" />
                            </div>
            
                            <div className="inputs-profile">
                            <label>Phone</label>
                            <input type="tel" id="phone" onChange={(e) => setPhone(e.target.value)} name="phone" placeholder="Your Phone Number" required />
                            </div>
                            

                            <div className="inputs-profile">
                            <label>Your Address</label>
                            <textarea id="address" onChange={(e) => setAddress(e.target.value)} name="address" rows="5" placeholder="Address and Location"></textarea>
                            </div>
                            
            
                            <button className="btn">Update Profile</button>
            
                        </form>
                        </div>
                    </div>

                    <div className="get-started">
                        <h2 className="started-title">Change Password</h2>
                        <div>
                        <form onSubmit={updatePassword}>
                            <div className="inputs-profile">
                                <label>Password</label>
                                <input type="password" onChange={(e) => setPassword(e.target.value)} id="password" name="password" placeholder="Password" required />
                            </div>
            
                            <div className="inputs-profile">
                                <label>Confirm Password</label>
                                <input type="password" onChange={(e) => setConfirmpassword(e.target.value)} id="confirmpassword" name="confirmpassword" placeholder="Confirm Password" required />
                            </div>

                            <button>Change Password</button>
            
                        </form>
                        </div>
                    </div>

                </section>
            </div>
        <DashboardFooter />

    </div>
  );
}

export default Profile;
