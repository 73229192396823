import Mainmenu from "./Mainmenu";
import Footer from "./Footer";

function Contactus() {
    document.title='Contact Us - Mecado Escolar';
  return (
    <div id="wrapper">
        {/* Main Menu */}
        <Mainmenu active='contactus'/>

        <div className="content-page">
            <h2 className="section-title">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.068.157 2.148.279 3.238.364.466.037.893.281 1.153.671L12 21l2.652-3.978c.26-.39.687-.634 1.153-.67 1.09-.086 2.17-.208 3.238-.365 1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                </svg>
                Ask us whats in your mind
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path fill-rule="evenodd" d="M9.638 1.093a.75.75 0 01.724 0l2 1.104a.75.75 0 11-.724 1.313L10 2.607l-1.638.903a.75.75 0 11-.724-1.313l2-1.104zM5.403 4.287a.75.75 0 01-.295 1.019l-.805.444.805.444a.75.75 0 01-.724 1.314L3.5 7.02v.73a.75.75 0 01-1.5 0v-2a.75.75 0 01.388-.657l1.996-1.1a.75.75 0 011.019.294zm9.194 0a.75.75 0 011.02-.295l1.995 1.101A.75.75 0 0118 5.75v2a.75.75 0 01-1.5 0v-.73l-.884.488a.75.75 0 11-.724-1.314l.806-.444-.806-.444a.75.75 0 01-.295-1.02zM7.343 8.284a.75.75 0 011.02-.294L10 8.893l1.638-.903a.75.75 0 11.724 1.313l-1.612.89v1.557a.75.75 0 01-1.5 0v-1.557l-1.612-.89a.75.75 0 01-.295-1.019zM2.75 11.5a.75.75 0 01.75.75v1.557l1.608.887a.75.75 0 01-.724 1.314l-1.996-1.101A.75.75 0 012 14.25v-2a.75.75 0 01.75-.75zm14.5 0a.75.75 0 01.75.75v2a.75.75 0 01-.388.657l-1.996 1.1a.75.75 0 11-.724-1.313l1.608-.887V12.25a.75.75 0 01.75-.75zm-7.25 4a.75.75 0 01.75.75v.73l.888-.49a.75.75 0 01.724 1.313l-2 1.104a.75.75 0 01-.724 0l-2-1.104a.75.75 0 11.724-1.313l.888.49v-.73a.75.75 0 01.75-.75z" clip-rule="evenodd" />
                </svg>
            </h2>
            <section id="contactus" className="contactus">
                <div>
                    <p>Fill and submit this contact form</p>
                    <form>
                        <div className="inputs">
                            <label>First Name</label>
                            <input type="text" id="sname" name="sname" placeholder="First Name" />
                        </div>

                        <div className="inputs">
                            <label>Middle Name</label>
                            <input type="text" id="oname" name="oname" placeholder="Middle Name" />
                        </div>

                        <div className="inputs">
                        <label>Other Name</label>
                        <input type="text" id="oname" name="oname" placeholder="Other Name" />
                        </div>

                        <div className="inputs">
                            <label>Email</label>
                            <input type="email" id="email" name="email" placeholder="Email Address" />
                        </div>

                        <div className="inputs">
                        <label>Phone Number</label>
                        <input type="text" id="phone" name="phone" placeholder="Phone Number" />
                        </div>

                        <div className="inputs">
                            <label>Subject</label>
                            <input type="text" id="subject" name="subject" placeholder="Subject info" />
                        </div>

                        <div className="inputs">
                            <label>Message</label>
                            <textarea id="message" name="message" rows="5" placeholder="Message information"></textarea>
                        </div>

                        <button className="btn">Submit Message</button>

                    </form>
                </div>
            </section>
        </div>

        {/* Footer */}
        <Footer />

    </div>
  );
}

export default Contactus;
