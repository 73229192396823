
import {Link} from 'react-router-dom';
import DashboardMenu from "./DashboardMenu";
import DashboardFooter from "./DashboardFooter";

import mercado_garden_3 from '../../images/mercado-garden-3.jpg';
import mercado_pool_3 from '../../images/mercado-pool-3.jpg';
import mercado_plants_2 from '../../images/mercado-plants-2.jpg';
import mercado_pool_1 from '../../images/mercado-pool-1.jpg';
import mercado_garden_5 from '../../images/mercado-garden-5.jpg';
import mercado_6 from '../../images/mercado-6.jpg';

import mercado_4 from '../../images/mercado-4.jpg';
import pool_2 from '../../images/mercado-pool-2.jpg';

import community_2 from '../../videos/lunamer-community-2.mp4';
import community_1 from '../../videos/lunamer-community-1.mp4';

import mercado_elec_2 from '../../images/mercado-elec-2.jpeg';
import mercado_garden_2 from '../../images/mercado-garden-2.jpg';
import LoadPosts from '../reusable/LoadPosts';



function Posts() {
    document.title='Mecado Escolar Posts';
  return (
    <div id="wrapper">
      
      {/* Dashboard Nav Here */}
        <DashboardMenu active='posts' />
            <div class="content-page">
                <h2 class="section-title">View student Posts
                <div>
                    <form>
                        <div class="inputs">
                            <input type="text" placeholder="Search Post" />
                            <button class="btn">
                                <span class="material-symbols-rounded">
                                search
                                </span>
                            </button>
                            </div>
                        </form>
                    </div>
            </h2>

            <LoadPosts ownerid='' />
            
            
            </div>
        <DashboardFooter />

    </div>
  );
}

export default Posts;
