import {Link} from 'react-router-dom';

import { useState } from 'react';


function Mainmenu({active}) {
  const [smallmenu,setSmallMenu]=useState(false);
    return (
      <div className="mainmenu">
        <div className="logo">
          <Link to="/">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
              <path fill-rule="evenodd" d="M11.622 1.602a.75.75 0 01.756 0l2.25 1.313a.75.75 0 01-.756 1.295L12 3.118 10.128 4.21a.75.75 0 11-.756-1.295l2.25-1.313zM5.898 5.81a.75.75 0 01-.27 1.025l-1.14.665 1.14.665a.75.75 0 11-.756 1.295L3.75 8.806v.944a.75.75 0 01-1.5 0V7.5a.75.75 0 01.372-.648l2.25-1.312a.75.75 0 011.026.27zm12.204 0a.75.75 0 011.026-.27l2.25 1.312a.75.75 0 01.372.648v2.25a.75.75 0 01-1.5 0v-.944l-1.122.654a.75.75 0 11-.756-1.295l1.14-.665-1.14-.665a.75.75 0 01-.27-1.025zm-9 5.25a.75.75 0 011.026-.27L12 11.882l1.872-1.092a.75.75 0 11.756 1.295l-1.878 1.096V15a.75.75 0 01-1.5 0v-1.82l-1.878-1.095a.75.75 0 01-.27-1.025zM3 13.5a.75.75 0 01.75.75v1.82l1.878 1.095a.75.75 0 11-.756 1.295l-2.25-1.312a.75.75 0 01-.372-.648v-2.25A.75.75 0 013 13.5zm18 0a.75.75 0 01.75.75v2.25a.75.75 0 01-.372.648l-2.25 1.312a.75.75 0 11-.756-1.295l1.878-1.096V14.25a.75.75 0 01.75-.75zm-9 5.25a.75.75 0 01.75.75v.944l1.122-.654a.75.75 0 11.756 1.295l-2.25 1.313a.75.75 0 01-.756 0l-2.25-1.313a.75.75 0 11.756-1.295l1.122.654V19.5a.75.75 0 01.75-.75z" clip-rule="evenodd" />
            </svg>
            <span>Mercado Escolar</span>
          </Link>
        </div>

        <div className="menus">
          <Link className={`${active==='home'?"active":""}`} to="/">Home</Link>
          <Link to="/aboutus" className={`${active==='aboutus'?"active":""}`}>Aboutus</Link>
          <Link to="/services" className={`${active==='services'?"active":""}`}>Services</Link>
          <Link to="/contactus" className={`${active==='contactus'?"active":""}`}>Contactus</Link>
          <Link to="/blog" className={`${active==='blog'?"active":""}`}>Blog Posts</Link>
        </div>

        <div className="login">
          <Link to="/register" className={`${active==='register'?"active":""}`}>Get Started</Link>
          <Link to="/login" className={`${active==='login'?"active":""}`}>Login</Link>
        </div>

        <div className={`menu ${smallmenu?"active":""}`} onClick={e => (setSmallMenu(!smallmenu))} id="menu">
          <Link to="#">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </Link>
        </div>

        <div className={`menu-close ${smallmenu?"":"active"}`} onClick={e => (setSmallMenu(!smallmenu))} id="menu-close">
          <Link to="#">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </Link>
        </div>

        <div className={`menus-small ${smallmenu?"active":""}`} id="menu-small">
          <Link className={`${active==='home'?"active":""}`} to="/">Home</Link>
          <Link to="/aboutus" className={`${active==='aboutus'?"active":""}`}>Aboutus</Link>
          <Link to="/services" className={`${active==='services'?"active":""}`}>Services</Link>
          <Link to="/contactus" className={`${active==='contactus'?"active":""}`}>Contactus</Link>
          <Link to="/blog" className={`${active==='blog'?"active":""}`}>Blog Posts</Link>
        </div>
      </div>
    );
  }
  
  export default Mainmenu;
  