
import {Link} from 'react-router-dom';
import DashAdminMenu from "./DashAdminMenu";
import DashAdminFooter from "./DashAdminFooter";

import mercado_garden_3 from '../../images/mercado-garden-3.jpg';
import mercado_pool_3 from '../../images/mercado-pool-3.jpg';
import mercado_plants_2 from '../../images/mercado-plants-2.jpg';
import mercado_pool_1 from '../../images/mercado-pool-1.jpg';
import mercado_garden_5 from '../../images/mercado-garden-5.jpg';
import mercado_4 from '../../images/mercado-4.jpg';

function AdminMarketPlace() {
    document.title='Mecado Escolar Admin Marketplace';
  return (
    <div id="wrapper">
      
      {/* Dashboard Nav Here */}
      <DashAdminMenu active='marketplace' />
        <div className="content-page">
            <h2 className="section-title">Business Products
                <div>
                    <form>
                        <div className="inputs">
                            <input type="text" placeholder="Search Products" />
                            <button className="btn">
                                <span className="material-symbols-rounded">
                                search
                                </span>
                            </button>
                            </div>
                        </form>
                    </div>
            </h2>
            
            <section id="visitor" className="visitor">
                <div className="get-started">
                    <h2 className="started-title">KLL Business One
                    <p>KLL Business One</p>
                    </h2>
                    <div className="visitor-illustration">
                        <img src={mercado_4}/>
                        <p>Light and Shinny Shoes </p>
                    </div>


                    <div className="visit-apartment">
                        <Link to="#">Purchases</Link>
                        <button className="btn">Delete</button>
                    </div>
                </div>

                <div className="get-started">
                    <h2 className="started-title">KLL Business One
                    <p>KLL Business One</p>
                    </h2>
                    <div className="visitor-illustration">
                        <img src={mercado_4}/>
                        <p>Light and Shinny Shoes </p>
                    </div>

                    

                    <div className="visit-apartment">
                        <Link to="#">Purchases</Link>
                        <button className="btn">Delete</button>
                    </div>
                </div>

                <div className="get-started">
                    <h2 className="started-title">KLL Business One
                    <p>KLL Business One</p>
                    </h2>
                    <div className="visitor-illustration">
                        <img src={mercado_4}/>
                        <p>Light and Shinny Shoes </p>
                    </div>

                    

                    <div className="visit-apartment">
                        <Link to="#">Purchases</Link>
                        <button className="btn">Delete</button>
                    </div>
                </div>
                
            </section>

            <section id="visitor" className="visitor">
                <div className="get-started">
                    <h2 className="started-title">KLL Business One
                    <p>KLL Business One</p>
                    </h2>
                    <div className="visitor-illustration">
                        <img src={mercado_4}/>
                        <p>Light and Shinny Shoes </p>
                    </div>

                    

                    <div className="visit-apartment">
                        <Link to="#">Purchases</Link>
                        <button className="btn">Delete</button>
                    </div>
                </div>

                <div className="get-started">
                    <h2 className="started-title">KLL Business One
                    <p>KLL Business One</p>
                    </h2>
                    <div className="visitor-illustration">
                        <img src={mercado_4}/>
                        <p>Light and Shinny Shoes </p>
                    </div>

                    

                    <div className="visit-apartment">
                        <Link to="#">Purchases</Link>
                        <button className="btn">Delete</button>
                    </div>
                </div>

                <div className="get-started">
                    <h2 className="started-title">KLL Business One
                    <p>KLL Business One</p>
                    </h2>
                    <div className="visitor-illustration">
                        <img src={mercado_4}/>
                        <p>Light and Shinny Shoes </p>
                    </div>

                    

                    <div className="visit-apartment">
                        <Link to="#">Purchases</Link>
                        <button className="btn">Delete</button>
                    </div>
                </div>
                
            </section>
        </div>
        <DashAdminFooter />

    </div>
  );
}

export default AdminMarketPlace;
