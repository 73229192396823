
import {Link} from 'react-router-dom';
import DashOwnerMenu from "./DashOwnerMenu";
import DashOwnerFooter from "./DashOwnerFooter";

import mercado_garden_3 from '../../images/mercado-garden-3.jpg';
import mercado_pool_3 from '../../images/mercado-pool-3.jpg';
import mercado_plants_2 from '../../images/mercado-plants-2.jpg';
import mercado_pool_1 from '../../images/mercado-pool-1.jpg';
import mercado_garden_5 from '../../images/mercado-garden-5.jpg';
import mercado_4 from '../../images/mercado-4.jpg';
import LoadMarkets from '../reusable/LoadMarkets';

function OwnerMarketPlace() {
    document.title='Mecado Escolar Marketplace';

    let mercadouser=null;
    if(localStorage.getItem('mercadouser')===null){
        mercadouser=false
    }
    else{
        mercadouser=JSON.parse(localStorage.getItem('mercadouser'))
    }


  return (
    <div id="wrapper">
      
      {/* Dashboard Nav Here */}
      <DashOwnerMenu active='marketplace' />
        <div className="content-page">
            <h2 className="section-title">Business Products
                <div>
                    <form>
                        <div className="inputs">
                            <input type="text" placeholder="Search Products" />
                            <button className="btn">
                                <span className="material-symbols-rounded">
                                search
                                </span>
                            </button>
                            </div>
                        </form>
                    </div>
            </h2>

            <LoadMarkets ownerid={mercadouser[0] && mercadouser[0]['id']} />
            
            
        </div>
        <DashOwnerFooter />

    </div>
  );
}

export default OwnerMarketPlace;
