
import axios from 'axios';
import { useState } from 'react';
import {Link} from 'react-router-dom';

function AddProduct() {
    let mercadouser=null;
    if(localStorage.getItem('mercadouser')===null){
        mercadouser=false
    }
    else{
        mercadouser=JSON.parse(localStorage.getItem('mercadouser'))
    }
    const [businessname,setBusinessname]=useState('');
    const [productname,setProductname]=useState('');
    const [productprice,setProductPrice]=useState('');
    const [productstock,setProductStock]=useState('');
    const [productdescription,setProductDescription]=useState('');
    const [uploadedphoto,setUploadedPhoto]=useState('');

    const saveProduct =(e) =>{
        e.preventDefault();

        if(businessname.trim()===""){
            alert("Business name is Missing")
            return
        }
        else if(businessname.length<4){
            alert("Business Name needs more than four characters");
            return
        }

        else if(uploadedphoto===""){
            alert("Photo is Missing")
            return
        }

        else if(productname.trim()===""){
            alert("Product name is Missing")
            return
        }
        else if(productname.length<4){
            alert("Product Name needs more than four characters");
            return
        }

        else if(productprice.trim()===""){
            alert("Price is Missing")
            return
        }
        else if(isNaN(productprice)){
            alert("Currency is required for Price");
            return
        }

        else if(productprice<0){
            alert("Price Should be more than 0.");
            return
        }

        else if(productstock.trim()===""){
            alert("Stock is Missing")
            return
        }
        else if(isNaN(productstock)){
            alert("Numbers are required for Stock");
            return
        }

        else if(productstock<0){
            alert("Stock Should be more than 0.");
            return
        }

        else if(productdescription.trim()===""){
            alert("Product Description is Missing")
            return
        }

        else if(productdescription.length<8){
            alert("Product Description needs more than eight characters");
            return
        }


        let frmdata=new FormData();
        
        frmdata.append('businessname',businessname);
        frmdata.append('productname',productname);
        frmdata.append('productprice',productprice);
        frmdata.append('productstock',productstock);
        frmdata.append('productdescription',productdescription);
        frmdata.append('uploadedphoto',uploadedphoto);
        frmdata.append('owner',mercadouser[0]['id']);
        axios.post(process.env.REACT_APP_PHP_URL+"saveproduct.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
        .finally(()=>{
            clearForm()
        })
    }

    const clearForm =() =>{
        setBusinessname('');
        setProductname('');
        setProductPrice('');
        setProductStock('');
        setProductDescription('');
        setUploadedPhoto('');
    }
  return (
    <div>
        <form onSubmit={saveProduct}>
            <div className="inputs-profile">
            <label>Upload Photo</label>
            <input type="file" id="photo" name="photo" onChange={(e) => setUploadedPhoto(e.target.files[0])} required />
            </div>

            <div className="inputs-profile">
                <label>Business Name</label>
                <input type="text" id="businessname" name="businessname" value={businessname} onChange={(e) => setBusinessname(e.target.value)} placeholder="Busines Name" required />
            </div>

            <div className="inputs-profile">
                <label>Product Name</label>
                <input type="text" id="productname" name="productname" value={productname} onChange={(e) => setProductname(e.target.value)} placeholder="Product Name" required />
            </div>
            
            <div className="inputs-profile">
                <label>Stock</label>
                <input type="number" id="stock" name="stock" value={productstock} onChange={(e) => setProductStock(e.target.value)} placeholder="0" required />
            </div>

            <div className="inputs-profile">
                <label>Price</label>
                <input type="text" id="price" name="price" value={productprice} onChange={(e) => setProductPrice(e.target.value)} placeholder="Price" required />
            </div>

            <div className="inputs-profile">
                <label>Product Description</label>
                <textarea id="description" name="description" value={productdescription} onChange={(e) => setProductDescription(e.target.value)} rows="5" placeholder="Product Description"></textarea>
            </div>
            
            <button className="btn">Save Product</button>
        </form>
    </div>
  );
}

export default AddProduct;