import logo from './logo.svg';


import {BrowserRouter as Router , Route, Routes} from 'react-router-dom';

import Home from './components/Home';
import Aboutus from './components/Aboutus';
import Services from './components/Services';
import Contactus from './components/Contactus';
import Blog from './components/Blog';
import Register from './components/Register';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import NotFound from './components/NotFound';

import Student from './components/student/Student';
import Businesses from './components/student/Businesses';
import Students from './components/student/Students';
import Chat from './components/student/Chat';
import Posts from './components/student/Posts';
import Clubs from './components/student/Clubs';
import Marketplace from './components/student/Marketplace';
import Profile from './components/student/Profile';
import Cart from './components/student/Cart';


import Owner from './components/business/Owner';
import OwnerProfile from './components/business/OwnerProfile';
import OwnerMarketPlace from './components/business/OwnerMarketplace';
import OwnerAdverts from './components/business/OwnerAdverts';
import OwnerChat from './components/business/OwnerChat';


import SchoolAdmin from './components/school/SchoolAdmin';
import SchoolProfile from './components/school/SchoolProfile';
import SchoolStudents from './components/school/SchoolStudents';
import SchoolBusinesses from './components/school/SchoolBusinesses';
import SchoolMarketPlace from './components/school/SchoolMarketPlace';
import SchoolPosts from './components/school/SchoolPosts';
import SchoolClubs from './components/school/SchoolClubs';
import SchoolAdverts from './components/school/SchoolAdverts';


import Admins from './components/admin/Admin';
import AdminBusinesses from './components/admin/AdminBusinesses';
import AdminStudents from './components/admin/AdminStudents';
import AdminPosts from './components/admin/AdminPosts';
import AdminClubs from './components/admin/AdminClubs';
import AdminMarketPlace from './components/admin/AdminMarketPlace';
import AdminsAdverts from './components/admin/AdminAdverts';
import AdminSchoolAdmins from './components/admin/AdminSchoolAdmins';
import AdminChat from './components/admin/AdminChat';
import AdminProfile from './components/admin/AdminProfile';




function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/aboutus' element={<Aboutus />} />
          <Route path='/services' element={<Services />} />
          <Route path='/contactus' element={<Contactus />} />
          
          <Route path='/blog' element={<Blog />} />

          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />

          <Route path='/forgot-password' element={<ForgotPassword />} />

          <Route path='/*' element={<NotFound />} />

          <Route path='/student/student' element={<Student />} />
          <Route path='/student/businesses' element={<Businesses />} />
          <Route path='/student/profile' element={<Profile />} />
          <Route path='/student/profiles' element={<Students />} />
          <Route path='/student/marketplace' element={<Marketplace />} />
          <Route path='/student/posts' element={<Posts />} />
          <Route path='/student/clubs' element={<Clubs />} />
          <Route path='/student/chats' element={<Chat />} />
          <Route path='/student/cart' element={<Cart />} />
          

          <Route path='/business/owner' element={<Owner />} />
          <Route path='/business/profile' element={<OwnerProfile />} />
          <Route path='/business/marketplace' element={<OwnerMarketPlace />} />
          <Route path='/business/adverts' element={<OwnerAdverts />} />
          <Route path='/business/chats' element={<OwnerChat />} />


          <Route path='/school/admin' element={<SchoolAdmin />} />
          <Route path='/school/profile' element={<SchoolProfile />} />
          <Route path='/school/businesses' element={<SchoolBusinesses />} />
          <Route path='/school/marketplace' element={<SchoolMarketPlace />} />
          <Route path='/school/profiles' element={<SchoolStudents />} />
          <Route path='/school/posts' element={<SchoolPosts />} />
          <Route path='/school/clubs' element={<SchoolClubs />} />
          <Route path='/school/adverts' element={<SchoolAdverts />} />

          <Route path='/admin/admin' element={<Admins />} />
          <Route path='/admin/businesses' element={<AdminBusinesses />} />
          <Route path='/admin/profiles' element={<AdminStudents />} />
          <Route path='/admin/posts' element={<AdminPosts />} />
          <Route path='/admin/clubs' element={<AdminClubs />} />
          <Route path='/admin/marketplace' element={<AdminMarketPlace />} />
          <Route path='/admin/adverts' element={<AdminsAdverts />} />
          <Route path='/admin/profile' element={<AdminProfile />} />
          <Route path='/admin/chats' element={<AdminChat />} />
          <Route path='/admin/schooladmins' element={<AdminSchoolAdmins />} />



          {/* 
          
          
          
          
           */}
          
        </Routes>
      </Router>
    </>
  );
}

export default App;
