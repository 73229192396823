
import {Link} from 'react-router-dom';
import DashAdminMenu from "./DashAdminMenu";
import DashAdminFooter from "./DashAdminFooter";
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';


function Admins() {
    document.title='Mecado Escolar Business Super Admin';

    let mercadouser=null;
    if(localStorage.getItem('mercadouser')===null){
        mercadouser=false
    }
    else{
        mercadouser=JSON.parse(localStorage.getItem('mercadouser'))
    }

    const [products,setProducts]=useState([]);
    const [clubs,setClubs]=useState([]);
    const [posts,setPosts]=useState([]);
    const [students,setStudents]=useState([]);
    const [owners,setOwners]=useState([]);

    useEffect(() => {
        getItems();
      }, []);

      const getItems = async (e) => {
        axios({
            method:'GET',
            url:process.env.REACT_APP_PHP_URL+"adminfetch.php?action=fetch_all",
            headers:{
                'content-type':'application/json',
            }
        })
        .then(response=>{
            setProducts(response.data.products);
            setClubs(response.data.clubs);
            setPosts(response.data.posts);
            setStudents(response.data.students);
            setOwners(response.data.owners);
        })
        .catch(error=>{
            setProducts([]);
            setClubs([]);
            setPosts([]);
            setStudents([]);
            setOwners([]);
        })
    };

    const onDelAccount = (user) => {
        let frmdata=new FormData();
        frmdata.append('id',user.id);
        axios.post(process.env.REACT_APP_PHP_URL+"deleteaccount.php",frmdata,null)
        .then(response=>{
            alert(response.data);
            window.location.reload();
        })
        .catch(error=>{
            alert(''+error)
        })
    }

    const onDelProduct = (product) => {
        let frmdata=new FormData();
        frmdata.append('id',product.id);
        axios.post(process.env.REACT_APP_PHP_URL+"deleteproducts.php",frmdata,null)
        .then(response=>{
            alert(response.data);
            window.location.reload();
        })
        .catch(error=>{
            alert(''+error)
        })
    }

    const onDelPost = (post) => {
        let frmdata=new FormData();
        frmdata.append('id',post.id);
        axios.post(process.env.REACT_APP_PHP_URL+"deletepost.php",frmdata,null)
        .then(response=>{
            alert(response.data);
            window.location.reload();
        })
        .catch(error=>{
            alert(''+error)
        })
    }


    const onDelAdvert = (post) => {
        let frmdata=new FormData();
        frmdata.append('id',post.id);
        axios.post(process.env.REACT_APP_PHP_URL+"deletepost.php",frmdata,null)
        .then(response=>{
            alert(response.data);
            window.location.reload();
        })
        .catch(error=>{
            alert(''+error)
        })
    }


    const onDelClub = (club) => {
        let frmdata=new FormData();
        frmdata.append('id',club.id);
        axios.post(process.env.REACT_APP_PHP_URL+"deleteclub.php",frmdata,null)
        .then(response=>{
            alert(response.data);
            window.location.reload();
        })
        .catch(error=>{
            alert(''+error)
        })
    }

    const onCancelOrder = (order) => {
        let frmdata=new FormData();
        frmdata.append('order',order.id);
        frmdata.append('update',"Cancelled");
        axios.post(process.env.REACT_APP_PHP_URL+"updateorder.php",frmdata,null)
        .then(response=>{
            alert(response.data);
            window.location.reload();
        })
        .catch(error=>{
            alert(''+error)
        })
    }


    


    const onReturnOrder = (order) => {
        let frmdata=new FormData();
        frmdata.append('order',order.id);
        frmdata.append('update',"Returned Successful");
        axios.post(process.env.REACT_APP_PHP_URL+"updateorder.php",frmdata,null)
        .then(response=>{
            alert(response.data);
            window.location.reload();
        })
        .catch(error=>{
            alert(''+error)
        })
    }

    const onAcceptOrder = (order) => {
        let frmdata=new FormData();
        frmdata.append('order',order.id);
        frmdata.append('update',"Accepted");
        axios.post(process.env.REACT_APP_PHP_URL+"updateorder.php",frmdata,null)
        .then(response=>{
            alert(response.data);
            window.location.reload();
        })
        .catch(error=>{
            alert(''+error)
        })
    }


    const onDeliverOrder = (order) => {
        let frmdata=new FormData();
        frmdata.append('order',order.id);
        frmdata.append('update',"Delivered");
        axios.post(process.env.REACT_APP_PHP_URL+"updateorder.php",frmdata,null)
        .then(response=>{
            alert(response.data);
            window.location.reload();
        })
        .catch(error=>{
            alert(''+error)
        })
    }
  return (
    <div id="wrapper">
      
      {/* Dashboard Nav Here */}
      <DashAdminMenu active='home' />
        <div className="content-page">
        <h2 className="section-title">
                Super Admin Dashboard
                <p> Welcome ({mercadouser[0] && mercadouser[0]['fname'] +" "+mercadouser[0]['oname']})</p>
            </h2>
            <section id="reports" className="reports">
                <div className="section-reports">
                    <div className="reports1">
                        <h4 className="">Students, Business Owners, Schools, Admins Products etc</h4>
                        <div id="chart_one"></div>
                    </div>

                    <div className="reports1">
                        <h4 className="">Students and Schools</h4>
                        <div id="chart_two"></div>
                    </div>

                    <div className="reports1">
                        <h4 className="">Business and Products</h4>
                        <div id="chart_three"></div>
                    </div>

                    <div className="reports1">
                        <h4 className="">Students and Posts</h4>
                        <div id="chart_four"></div>
                    </div>

                    <div className="reports1">
                        <h4 className="">Posts, clubs , adverts etc</h4>
                        <div id="chart_five"></div>
                    </div>
                </div>

                <div className="section-tables">

                    <div className="tables1">
                        <h2 className="">Students</h2>
                        <table>
                            <thead>
                                <th>#</th>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                            {students && students.map((student,key) => (
                                <tr>
                                <td>{key+1}</td>
                                <td>{student.fname} {student.mname}</td>
                                <td>{student.phone}</td>
                                <td>
                                    <button onClick={()=>onDelAccount(student)}>
                                        <span className="material-symbols-rounded">
                                        cancel
                                        </span>
                                    </button>
                                </td>
                            </tr>
                            ))} 

                            </tbody>
                        </table>
                    </div>


                    <div className="tables1">
                        <h2 className="">Business Owners</h2>
                        <table>
                            <thead>
                                <th>#</th>
                                <th>Business</th>
                                <th>Owner</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                            {owners && owners.map((owner,key) => (
                                <tr>
                                <td>{key+1}</td>
                                <td>{owner.businessname}</td>
                                <td>{owner.fname} {owner.mname}</td>
                                <td>
                                    <button onClick={()=>onDelAccount(owner)}>
                                        <span className="material-symbols-rounded">
                                        cancel
                                        </span>
                                    </button>
                                </td>
                            </tr>
                            ))} 
                                
                            </tbody>
                        </table>
                    </div>


                    <div className="tables1">
                        <h2 className="">Products</h2>
                        <table>
                            <thead>
                                <th>#</th>
                                <th>Product</th>
                                <th>Qty</th>
                                <th>Price</th>
                            </thead>
                            <tbody>
                            {products && products.map((product,key) => (
                                <tr>
                                <td>{key+1}</td>
                                <td>{product.productname}</td>
                                <td>{product.productstock}</td>
                                <td>{product.productprice}</td>
                                <td>
                                    <button onClick={()=>onDelProduct(product)}>
                                        <span className="material-symbols-rounded">
                                        cancel
                                        </span>
                                    </button>
                                </td>
                            </tr>
                            ))} 
                                

                            </tbody>
                        </table>
                    </div>
        
                    <div className="tables1">
                        <h2 className=""> Posts</h2>
                        <table>
                            <thead>
                                <th>#</th>
                                <th>Post</th>
                                <th>Type</th>
                                <th>School</th>
                            </thead>
                            <tbody>
                            {posts && posts.map((post,key) => (
                                <tr>
                                    <td>{key+1}</td>
                                    <td>{post.postinformation}</td>
                                    <td>{post.posttype}</td>
                                    <td>School One</td>
                                    <td>
                                    <button onClick={()=>onDelPost(post)}>
                                        <span className="material-symbols-rounded">
                                        cancel
                                        </span>
                                    </button>
                                </td>
                                </tr>
                            ))} 
                                

                            </tbody>
                        </table>
                    </div>

                    <div className="tables1">
                        <h2 className=""> Clubs
                        </h2>
                        <table>
                            <thead>
                                <th>#</th>
                                <th>Club</th>
                                <th>Information</th>
                                <th>Members</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                            {clubs && clubs.map((club,key) => (
                                <tr>
                                <td>{key+1}</td>
                                <td>{club.clubname}</td>
                                <td>{club.clubinformation}</td>
                                <td>{club.owner}</td>
                                <td>
                                    <button onClick={()=>onDelClub(club)}>
                                        <span className="material-symbols-rounded">
                                        cancel
                                        </span>
                                    </button>
                                </td>
                                </tr>
                            ))} 

                            </tbody>
                        </table>
                    </div>

                </div>

                
                


            </section>
            
           
        </div>
        <DashAdminFooter />

    </div>
  );
}

export default Admins;
