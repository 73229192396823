import Mainmenu from "./Mainmenu";
import Footer from "./Footer";
import {Link} from 'react-router-dom';
import { useState } from 'react';
import RegisterStudent from "./RegisterStudent";
import RegisterBusiness from "./RegisterBusiness";

function Register() {
    document.title='Register - Mecado Escolar';
    
    const [register,setRegister]=useState('');
  return (
    <div id="wrapper">
        {/* Main Menu */}
        <Mainmenu active='register' />

            {register=='' &&
                <div className="content-page">
                    <h5>Create Account to login and access Marketplace services</h5>
                    <section id="register" className="register">
                        <div className="get-started">
                        <h2 className="started-title">Students
                            <p>Create a Student Account</p>
                        </h2>
                        <p>A student is able to buy products from Businesses online and also able to sell some. Student Can still create clubs and invite fellow students to join </p>
                            <button className="btn" onClick={e => (setRegister('student'))}>Register as a Student</button>
                            {/* <a className="start-visitor" href="register-student.html">Register as a Student</a> */}
                        </div>

                        <div className="get-started">
                        <h2 className="started-title">Businesses
                            <p>Business Owners</p>
                        </h2>
                        <p>Register as Business owners to be able to manage your business, add products, advertize and sell the to students  </p>
                        <button className="btn" onClick={e => (setRegister('business'))}>Register as a Business Owner</button>
                        {/* <a className="start-resident" href="register-businesses.html">Register as a Business Owner</a> */}
                        </div>
                    </section>

                </div>
            }

            {register=='student' &&
                <RegisterStudent setRegister={setRegister}/>
            }

            {register=='business' &&
                <RegisterBusiness setRegister={setRegister}/>
            }

      
        {/* Footer */}
        <Footer />

    </div>
  );
}

export default Register;
