import axios from "axios";
import { useState } from "react";


function RegisterBusiness({setRegister}) {
    document.title='Register Business - Mecado Escolar';
    const [fname,setFname]=useState('');
    const [mname,setMname]=useState('');
    const [oname,setOname]=useState('');
    const [email,setEmail]=useState('');
    const [phone,setPhone]=useState('');
    const [address,setAddress]=useState('');
    const [userID,setUserID]=useState('');
    const [businessname,setBusinessname]=useState('');
    const [businessaddress,setBusinessAddress]=useState('');
    const [aboutbusiness,setAboutBusiness]=useState('');
    const [password,setPassword]=useState('');
    const [confirmpassword,setConfirmPassword]=useState('');
    const [uploadedphoto,setUploadedPhoto]=useState('');
    

    const saveBusinessOwner =(e) =>{
        e.preventDefault();

        if(fname.trim()===""){
            alert("First name is Missing")
            return
        }
        else if(fname.length<4){
            alert("First Name needs more than four characters");
            return
        }
        else if(mname.trim()===""){
            alert("Middle name is Missing")
            return
        }
        else if(mname.length<4){
            alert("Middle Name needs more than four characters");
            return
        }

        else if(oname.trim()===""){
            alert("Other name is Missing")
            return
        }
        else if(oname.length<4){
            alert("Other Name needs more than four characters");
            return
        }

        else if(email.trim()===""){
            alert("Email is Missing")
            return
        }
        
        else if(email.indexOf('@') <2 ){
            alert("Email looks too smaller");
            return
        }
        else if(userID.trim()===""){
            alert("User ID is Missing")
            return
        }

        else if(isNaN(userID)){
            alert("Numbers are required for User ID");
            return
        }

        else if(address.trim()===""){
            alert("Address is Missing")
            return
        }

        else if(address.length<8){
            alert("Address needs more than eight characters");
            return
        }

        else if(businessname.trim()===""){
            alert("Business Name is Missing")
            return
        }

        else if(businessname.length<5){
            alert("Business Name needs more than five characters");
            return
        }

        else if(businessaddress.trim()===""){
            alert("Business Address is Missing")
            return
        }

        else if(businessaddress.length<8){
            alert("Business Address needs more than eight characters");
            return
        }

        else if(aboutbusiness.trim()===""){
            alert("Business About Information is Missing")
            return
        }

        else if(aboutbusiness.length<10){
            alert("Business About Information needs more than ten characters");
            return
        }

        else if(isNaN(phone)){
            alert("Numbers are required for Phone");
            return
        }

        else if(phone.length!==10){
            alert("Phone must be 10 digits");
            return
        }

        else if(uploadedphoto===""){
            alert("Photo is Missing")
            return
        }

        else if(password.trim()===""){
            alert("Password is Missing")
            return
        }

        else if(password.trim().length<7){
            alert("Password should be more than 7 characters")
            return
        }

        else if(password.trim()!==confirmpassword.trim()){
            alert("Confirm Password does not match your Password")
            return
        }

        let frmdata=new FormData();
        frmdata.append('fname',fname);
        frmdata.append('mname',mname);
        frmdata.append('oname',oname);
        frmdata.append('phone',phone);
        frmdata.append('email',email);
        frmdata.append('address',address);
        frmdata.append('businessname',businessname);
        frmdata.append('businessaddress',businessaddress);
        frmdata.append('aboutbusiness',aboutbusiness);
        frmdata.append('uploadedphoto',uploadedphoto);
        frmdata.append('userID',userID);
        frmdata.append('password',password);
        
        axios.post(process.env.REACT_APP_PHP_URL+"savebusinessowner.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
        .finally(()=>{
            clearForm()
        })
    }

    const clearForm =() =>{
        setFname('');
        setMname('');
        setOname('');
        setEmail('');
        setPhone('');
        setAddress('');
        setBusinessname('');
        setBusinessAddress('');
        setAboutBusiness('');
        setUserID('');
        setPassword('');
        setConfirmPassword('');
        setUploadedPhoto('');
    }
  return (
    <div id="wrapper">
        <div class="content-page">
            <h2 class="section-title">
                Register A business Account
                <p><button className="btn" onClick={e => (setRegister(''))}> Back to Register</button></p>
            </h2>
            <section id="contactus" class="contactus">
                <div>
                <p>This information will help you create A Business Account to aid in Managing your Business and Products</p>
                <form onSubmit={saveBusinessOwner}>
                    <div className="inputs">
                        <label>First Name</label>
                        <input type="text" id="fname" name="fname" value={fname} onChange={(e) => setFname(e.target.value)} placeholder="First Name" />
                    </div>

                    <div className="inputs">
                        <label>Middle Name</label>
                        <input type="text" id="mname" name="mname" value={mname} onChange={(e) => setMname(e.target.value)} placeholder="Middle Name" />
                    </div>

                    <div className="inputs">
                        <label>Other Name</label>
                        <input type="text" id="oname" name="oname" value={oname} onChange={(e) => setOname(e.target.value)} placeholder="Other Name" />
                    </div>

                    <div className="inputs">
                        <label>Phone</label>
                        <input type="tel" id="phone" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Your Phone Number" required />
                    </div>

                    <div className="inputs">
                        <label>Email</label>
                        <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" />
                    </div>
                    
                    <div className="inputs">
                        <label>ID</label>
                        <input type="tel" id="visitorid" name="visitorid" value={userID} onChange={(e) => setUserID(e.target.value)} placeholder="ID Number" required />
                    </div>

                    <div className="inputs">
                        <label>Your Address</label>
                        <textarea id="address" name="address" rows="5" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Address and Location"></textarea>
                    </div>

                    <div class="inputs">
                        <label>Business Name</label>
                        <input type="text" id="oname" name="oname" value={businessname} onChange={(e) => setBusinessname(e.target.value)} placeholder="Business Name" />
                    </div>

                    <div class="inputs">
                        <label>Business Address</label>
                        <textarea id="businessaddress" name="businessaddress" value={businessaddress} onChange={(e) => setBusinessAddress(e.target.value)} rows="5" placeholder="Business Address and Location"></textarea>
                    </div>

                    <div class="inputs">
                        <label>About Business</label>
                        <textarea id="businessinfor" name="businessinfor" value={aboutbusiness} onChange={(e) => setAboutBusiness(e.target.value)} rows="5" placeholder="What your business is about or sells"></textarea>
                    </div>
                    
                    <div class="inputs">
                        <label>Upload Business Photo</label>
                        <input type="file" id="photo" name="photo" onChange={(e) => setUploadedPhoto(e.target.files[0])} required />
                    </div>

                    <div className="inputs">
                        <label>Password</label>
                        <input type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
                    </div>

                    <div className="inputs">
                        <label>Confirm Password</label>
                        <input type="password" id="confirmpassword" name="confirmpassword" value={confirmpassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password" required />
                    </div>

                    <button class="btn">Create Business Account</button>

                </form>
                </div>
            </section>
        </div>

    </div>
  );
}

export default RegisterBusiness;
