
import {Link} from 'react-router-dom';
import DashSchoolMenu from "./DashSchoolMenu";
import DashSchoolFooter from "./DashSchoolFooter";

import mercado_garden_3 from '../../images/mercado-garden-3.jpg';
import mercado_pool_3 from '../../images/mercado-pool-3.jpg';

import mercado_pool_2 from '../../images/mercado-pool-2.jpg';
import mercado_plants_2 from '../../images/mercado-plants-2.jpg';
import mercado_pool_1 from '../../images/mercado-pool-1.jpg';
import mercado_garden_5 from '../../images/mercado-garden-5.jpg';
import mercado_6 from '../../images/mercado-6.jpg';

import mercado_4 from '../../images/mercado-4.jpg';
import mercado_elec_2 from '../../images/mercado-elec-2.jpeg';
import mercado_garden_2 from '../../images/mercado-garden-2.jpg';
import LoadClubs from '../reusable/LoadClubs';



function SchoolClubs() {
    document.title='Mecado Escolar School Clubs';
  return (
    <div id="wrapper">
      
      {/* Dashboard Nav Here */}
        <DashSchoolMenu active='club' />
            <div class="content-page">
                    <h2 class="section-title">View student Clubs
                    <div>
                        <form>
                            <div class="inputs">
                                <input type="text" placeholder="Search Student Clubs" />
                                <button class="btn">
                                    <span class="material-symbols-rounded">
                                    search
                                    </span>
                                </button>
                                </div>
                            </form>
                        </div>
                </h2>

                <LoadClubs ownerid='' />
                
                {/* <section id="visitor" class="visitor">
                    

                    <div class="get-started">
                        
                        <div class="visitor-illustration">
                            <img src={mercado_pool_2}/>
                            <p>Decided to Swim</p>
                        </div>

                        <div>
                            <p>Today with my family, we devided to give Lunamar waters a try. </p>
                        </div>

                        <div class="visit-apartment">
                            <a href="">Join <span class="comments">0</span></a>
                            <button class="btn">View</button>
                            <button className='btn'>Delete</button>
                            
                        </div>
                    </div>

                    <div class="get-started">
                        
                        <div class="visitor-illustration">
                            <img src={mercado_pool_2}/>
                            <p>Decided to Swim</p>
                        </div>

                        <div>
                            <p>Today with my family, we devided to give Lunamar waters a try. </p>
                        </div>

                        <div class="visit-apartment">
                            <a href="">Join <span class="comments">0</span></a>
                            <button class="btn">View</button>
                            <button className='btn'>Delete</button>
                            
                        </div>
                    </div>


                    <div class="get-started">
                        
                        <div class="visitor-illustration">
                            <img src={mercado_pool_2}/>
                            <p>Decided to Swim</p>
                        </div>

                        <div>
                            <p>Today with my family, we devided to give Lunamar waters a try. </p>
                        </div>

                        <div class="visit-apartment">
                            <a href="">Join <span class="comments">0</span></a>
                            <button class="btn">View</button>
                            <button className='btn'>Delete</button>
                            
                        </div>
                    </div>
                </section> */}
            </div>
        <DashSchoolFooter />

    </div>
  );
}

export default SchoolClubs;
