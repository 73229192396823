import { useState,useEffect } from 'react';

import Mainmenu from "./Mainmenu";
import Footer from "./Footer";
import mercado_plants_2 from '../images/mercado-plants-2.jpg';
import mercado_build_1 from '../images/mercado-build-1.jpg';
import LoadComments from './LoadComments';

function Blog() {
  document.title='Blog Posts - Mecado Escolar';
    let wp_post_URL='http://localhost/mercadoescolar/blog/wp-json/wp/v2/posts';
    const [posts,setPosts]=useState(null);
    useEffect(() =>{
        async function loadPosts(){
            const response=await fetch(wp_post_URL);
            if(!response.ok){
                return;
            }
            const post=await response.json();
            setPosts(post);
        }
        loadPosts();
    },[posts]);

  return (
    <div id="wrapper">
        {/* Main Menu */}
        <Mainmenu active='blog' />
        <div className="content-page">
            <p style={{"padding":"10px","fontSize":"20px"}}>
                There is more in our blog...
                <br/>
                <a href="http://localhost/mercadoescolar/blog" target="_blank" className="">
                    Blog Here
                </a>
            </p>

            <section id="visitor" class="visitor">
                {!posts &&
                    <div class="get-started">
                        <div>
                            <h6  style={{"textAlign":"center","padding-bottom":"10px"}}>
                                Loading Blog Posts and Comments...
                            </h6>
                            

                            <p>
                               Your Posts will be loaded here...
                            </p>
                        </div>
                    </div>
                }
                {posts && posts.map((post,index) =>(
                    <div class="get-started" key={index}>
                        <div>
                            <h6  style={{"textAlign":"left","padding-bottom":"10px"}}>
                                <a href={post.link} target="_blank" className="">
                                {post.title.rendered}
                                </a>
                            </h6>
                            <div dangerouslySetInnerHTML={{__html: post.content.rendered}} />

                            <p>
                                <span style={{"float":"left","fontSize":"12px"}}>
                                    {post.date}
                                </span>
                                <span style={{"float":"right"}}>
                                    <span class="comments" style={{"padding":"9px"}}> <LoadComments post_id={post.id} /></span> Comments
                                </span>
                            </p>
                        </div>
                    </div>
                ))}

                {/* <div class="get-started">
                    <div>
                        <h6  style={{"textAlign":"left","padding-bottom":"10px"}}>
                            <a href="http://localhost/mercadoescolar/blog/comments" target="_blank" className="">
                                Was delighted to take a tour at last
                            </a>
                        </h6>
                        <p>This week, our club decided to give us a guided tour about properties. </p>
                        <p>
                            <span style={{"float":"right"}}>
                                <span class="comments">3</span> Comments
                            </span>
                        </p>
                    </div>

                    
                </div>

                <div class="get-started">

                    <div>
                        <h6  style={{"textAlign":"left","padding-bottom":"10px"}}>
                            <a href="http://localhost/mercadoescolar/blog/comments" target="_blank" className="">
                                Was delighted to take a tour at last
                            </a>
                        </h6>
                        <p>Today with my family, we devided to give Lunamar waters a try. </p>
                        <p>
                            <span style={{"float":"right"}}>
                                <span class="comments">3</span> Comments
                            </span>
                        </p>
                    </div>
                    
                </div> */}
            </section>
        </div>
        {/* Footer */}
        <Footer />

    </div>
  );
}

export default Blog;
