
import {Link} from 'react-router-dom';
import DashOwnerMenu from "./DashOwnerMenu";
import DashOwnerFooter from "./DashOwnerFooter";
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import AddProduct from '../reusable/AddProduct';
import AddAdvert from '../reusable/AddAdvert';

function Owner() {
    document.title='Mecado Escolar Business Owner';

    let mercadouser=null;
    if(localStorage.getItem('mercadouser')===null){
        mercadouser=false
    }
    else{
        mercadouser=JSON.parse(localStorage.getItem('mercadouser'))
    }

    const [products,setProducts]=useState([]);
    const [clubs,setClubs]=useState([]);
    const [posts,setPosts]=useState([]);
    const [orders,setOrders]=useState([]);

    useEffect(()=>{
        let success=true;
        const getYourProducts = async (e) => {
            axios({
                method:'GET',
                url:process.env.REACT_APP_PHP_URL+"loadproducts.php",
                params:{
                    owner:mercadouser[0]['id'],
                },
                headers:{
                    'content-type':'application/json',
                }
            })
            .then(response=>{
                if (success) {
                    if(response.data.response){
                        setProducts(response.data.products);
                    }
                    else{
                        setProducts([]);
                    }
                }
            })
            .catch(error=>{
                setProducts([]);
            })
        };
        getYourProducts();

        return ()=>{
            success=false;
        }

    },[products])


    useEffect(()=>{
        let success=true;
        const getYourClubs = async (e) => {
            axios({
                method:'GET',
                url:process.env.REACT_APP_PHP_URL+"loadclubs.php",
                params:{
                    owner:mercadouser[0]['id'],
                },
                headers:{
                    'content-type':'application/json',
                }
            })
            .then(response=>{
                if (success) {
                    if(response.data.response){
                        setClubs(response.data.clubs);
                    }
                    else{
                        setClubs([]);
                    }
                }
            })
            .catch(error=>{
                setClubs([]);
            })
        };
        getYourClubs();

        return ()=>{
            success=false;
        }

    },[clubs])


    useEffect(()=>{
        let success=true;
        const getYourPosts = async (e) => {
            axios({
                method:'GET',
                url:process.env.REACT_APP_PHP_URL+"loadposts.php",
                params:{
                    creator:mercadouser[0]['id'],
                },
                headers:{
                    'content-type':'application/json',
                }
            })
            .then(response=>{
                if (success) {
                    if(response.data.response){
                        setPosts(response.data.posts);
                    }
                    else{
                        setPosts([]);
                    }
                }
            })
            .catch(error=>{
                setPosts([]);
            })
        };
        getYourPosts();

        return ()=>{
            success=false;
        }

    },[posts])

    useEffect(()=>{
        let success=true;
        const getYourOrders = async (e) => {
            axios({
                method:'GET',
                url:process.env.REACT_APP_PHP_URL+"loadbusinessorders.php",
                params:{
                    seller:mercadouser[0]['id'],
                },
                headers:{
                    'content-type':'application/json',
                }
            })
            .then(response=>{
                if (success) {
                    if(response.data.response){
                        setOrders(response.data.orders);
                    }
                    else{
                        setOrders([]);
                    }
                }
            })
            .catch(error=>{
                setOrders([]);
            })
        };
        getYourOrders();

        return ()=>{
            success=false;
        }

    },[orders])

    const onDelProduct = (product) => {
        let frmdata=new FormData();
        frmdata.append('id',product.id);
        axios.post(process.env.REACT_APP_PHP_URL+"deleteproducts.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }


    const onDelAdvert = (post) => {
        let frmdata=new FormData();
        frmdata.append('id',post.id);
        axios.post(process.env.REACT_APP_PHP_URL+"deletepost.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }


    const onDelClub = (club) => {
        let frmdata=new FormData();
        frmdata.append('id',club.id);
        axios.post(process.env.REACT_APP_PHP_URL+"deleteclub.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }

    const onCancelOrder = (order) => {
        let frmdata=new FormData();
        frmdata.append('order',order.id);
        frmdata.append('update',"Cancelled");
        axios.post(process.env.REACT_APP_PHP_URL+"updateorder.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }


    


    const onReturnOrder = (order) => {
        let frmdata=new FormData();
        frmdata.append('order',order.id);
        frmdata.append('update',"Returned Successful");
        axios.post(process.env.REACT_APP_PHP_URL+"updateorder.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }

    const onAcceptOrder = (order) => {
        let frmdata=new FormData();
        frmdata.append('order',order.id);
        frmdata.append('update',"Accepted");
        axios.post(process.env.REACT_APP_PHP_URL+"updateorder.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }


    const onDeliverOrder = (order) => {
        let frmdata=new FormData();
        frmdata.append('order',order.id);
        frmdata.append('update',"Delivered");
        axios.post(process.env.REACT_APP_PHP_URL+"updateorder.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }
  return (
    <div id="wrapper">
      
      {/* Dashboard Nav Here */}
      <DashOwnerMenu active='home' />
        <div className="content-page">
            <h2 className="section-title">
                Business Owner Dashboard
                <p> Welcome ({mercadouser[0] && mercadouser[0]['fname'] +" "+mercadouser[0]['oname']})</p>
            </h2>
            <section id="register" className="register">
                <div className="get-started">
                <h2 className="">
                    <Link className="visitor-btn" to="/business/marketplace">
                    View Market Place
                    </Link>
                    Student Orders
                </h2>
                    <table>
                        <thead>
                            <th>#</th>
                            <th>Order</th>
                            <th>Student</th>
                            <th>Total</th>
                            <th>Status</th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                            {orders && orders.map((order,key) => (
                                <tr>
                                    <td>{key+1}</td>
                                    <td>ORD{order.id}</td>
                                    <td>{order.buyername}</td>
                                    <td>${order.price * order.quantity}</td>
                                    <td>{order.status}</td>
                                    <td>
                                        <button>View</button>
                                        {order.status==="Pending" &&
                                            <>
                                            <button onClick={() => onAcceptOrder(order)}>Confirm</button>
                                            <button onClick={() => onCancelOrder(order)}>Cancel</button>
                                            </>
                                        }

                                        {order.status==="Accepted" &&
                                            <button onClick={() => onDeliverOrder(order)}>Deliver</button>
                                        }

                                        {order.status==="Returned" &&
                                            <button onClick={() => onReturnOrder(order)}>Received</button>
                                        }
                                        
                                    </td>
                                </tr>
                                                                                
                            ))} 
                            
                        </tbody>
                    </table>
                </div>

                <div className="get-started">
                <h2 className="">
                    <Link className="visitor-btn" to="/business/adverts">
                        View Adverts
                    </Link>
                    Your Adverts
                </h2>
                    <table>
                        <thead>
                            <th>#</th>
                            <th>Advert</th>
                            <th>Time</th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                            {posts && posts.map((post,key) => (
                                <tr>
                                    <td>{key+1}</td>
                                    <td>{post.postinformation}</td>
                                    <td>{post.created_at}</td>
                                    <td>
                                    <button>View</button>
                                        <button onClick={() => onDelAdvert(post)}>Delete</button>
                                    </td>
                                </tr>
                                                                                
                            ))} 
                            
                        </tbody>
                    </table>
                </div>

            </section>

                

            <section id="register" className="register">
                <div className="get-started ">
                <h5 className="">
                    Add Your Products
                </h5>
                <AddProduct />

                <h3>Your Products</h3>
                <table>
                    <thead>
                        <th>#</th>
                        <th>Product</th>
                        <th>Stock</th>
                        <th>Price</th>
                        <th>Sold</th>
                        <th>Action</th>
                    </thead>
                    <tbody>
                    {products && products.map((product,key) => (
                        <tr>
                            <td>{key+1}</td>
                            <td>{product.productname}</td>
                            <td>{product.productstock}</td>
                            <td>${product.productprice}</td>
                            <td>{product.sold}</td>
                            <td>
                                <button>purchases</button>
                                <button>Edit</button>
                                <button onClick={() => onDelProduct(product)}>Delete</button>
                            </td>
                        </tr>
                                                                        
                    ))}  
                        
                    </tbody>
                </table>
                
                </div>

                <div className="get-started">
                    <h5 className="">
                        Create Advert
                    </h5>
                    <AddAdvert />
                </div>


            </section>
        </div>
        <DashOwnerFooter />

    </div>
  );
}

export default Owner;
