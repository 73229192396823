
import {Link} from 'react-router-dom';
import DashboardMenu from "./DashboardMenu";
import DashboardFooter from "./DashboardFooter";
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';


function Cart() {
    document.title='Mecado Escolar Cart';
    let mercadouser=null;
    if(localStorage.getItem('mercadouser')===null){
        mercadouser=false
    }
    else{
        mercadouser=JSON.parse(localStorage.getItem('mercadouser'))
    }

    const [cartitems,setCartItems]=useState(0);
    const [carts,setCarts]=useState([]);
    const [total,setTotal]=useState(0.00);

    useEffect(()=>{
        let success=true;
        const getcartItems = async (e) => {
            axios({
                method:'GET',
                url:process.env.REACT_APP_PHP_URL+"loadcartitems.php",
                params:{
                    buyer:mercadouser[0]['id'],
                },
                headers:{
                    'content-type':'application/json',
                }
            })
            .then(response=>{
                if (success) {
                    if(response.data.response){
                        setCartItems(response.data.cartitem);
                        setCarts(response.data.carts);
                        setTotal(response.data.total);
                    }
                    else{
                        setCartItems(0);
                        setCarts([]);
                        setTotal([]);
                    }
                }
            })
            .catch(error=>{
                setCartItems(0);
                setCarts([]);
                setTotal([]);
            })
        };
        getcartItems();

        return ()=>{
            success=false;
        }

    },[carts])

    const onRemove = (cart) => {
        let frmdata=new FormData();
        frmdata.append('cartid',cart.id);
        axios.post(process.env.REACT_APP_PHP_URL+"removecartitem.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }
    
    const onEmpty = (e) => {
        let frmdata=new FormData();
        frmdata.append('buyer',mercadouser[0]['id']);
        axios.post(process.env.REACT_APP_PHP_URL+"emptycart.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }


    const onPlaceOrder = (e) => {
        let frmdata=new FormData();
        frmdata.append('buyer',mercadouser[0]['id']);
        axios.post(process.env.REACT_APP_PHP_URL+"placeorder.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }
    

  return (
    <div id="wrapper">
      
      {/* Dashboard Nav Here */}
      <DashboardMenu active='home' />
        <div className="content-page">
            <h2 className="section-title">
                Student Cart <span >Cart <sup className='cart'>{cartitems && cartitems}</sup></span>
            </h2>
            <section id="register" className="register">
                <div className="get-started">
                <h2 className="">
                    Your Cart Items
                </h2>
                    <table>
                        <thead>
                            <th>#</th>
                            <th>Product</th>
                            <th>Price</th>
                            <th>Qty</th>
                            <th>Total</th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                        {carts && carts.map((cart,key) => (
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{cart.productname}</td>
                                <td>${cart.price}</td>
                                <td>{cart.quantity}</td>
                                <td>${cart.quantity * cart.price}</td>
                                <td>
                                    <button onClick={() => onRemove(cart)}>Remove</button>
                                </td>
                            </tr>                        
                        ))} 

                            <tr >
                                <td colSpan={2}><button className='btn' onClick={(e) => onEmpty(e)}>Empty Cart</button></td>
                                <td colSpan={2}>Total</td>
                                <td>${total}</td>
                                {/* <td>${cart.quantity * cart.price}</td> */}
                                <td>
                                    <button className='btn' onClick={(e) => onPlaceOrder(e)}>Place Order</button>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>


            </section>

            
        </div>
        <DashboardFooter />

    </div>
  );
}

export default Cart;