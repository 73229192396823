import Mainmenu from "./Mainmenu";
import Footer from "./Footer";

import mercado_plants_2 from '../images/mercado-plants-2.jpg';
import mercado_build_1 from '../images/mercado-build-1.jpg';

function Aboutus() {
  document.title='About Us - Mecado Escolar';
  return (
    <div id="wrapper">
        {/* Main Menu */}
        <Mainmenu active='aboutus' />
        <div className="content-page">
            <section id="about" className="about">
                <div className="about-information">
                <h4>Who and What does Mercado Escolar do?</h4>
                <p>Mercado Escolar is a community of online buyers and sellers mostly between students and business owners around schools. 
                </p>
                <p>It also provides an interactive platform for students to share information and create club inviting each other</p>
                </div>
                <div className="about-illustration">
                <img src={mercado_plants_2}/>
                <p>Ideal Environment for student Clubs </p>
                </div>
            </section>

            <section id="about" className="about">
                <div className="about-illustration">
                <img src={mercado_build_1}/>
                <p>A Business Managament opportunity </p>
                </div>
                <div className="about-information">
                <h4>Linking and being a Mercado Escolar Member?</h4>
                <p>Get Started provides a link to create your accounts. Student account may also sell their items no longer in need of.
                </p>
                <p>For Businesses, their account only helps them to create products, market them and sell</p>
                </div>
            </section>
        </div>
        {/* Footer */}
        <Footer />

    </div>
  );
}

export default Aboutus;
