
import {Link} from 'react-router-dom';
import Mainmenu from "./Mainmenu";
import Footer from "./Footer";
import mercado_garden_3 from '../images/mercado-garden-3.jpg';
import mercado_pool_3 from '../images/mercado-pool-3.jpg';
import mercado_plants_2 from '../images/mercado-plants-2.jpg';
import mercado_pool_1 from '../images/mercado-pool-1.jpg';
import mercado_garden_5 from '../images/mercado-garden-5.jpg';
import mercado_6 from '../images/mercado-6.jpg';


function NotFound() {
    document.title='Mecado Escolar Not Found Page';
  return (
    <div id="wrapper">
      
      {/* Nav Here */}
      {/* <Mainmenu active='home' /> */}
        <div className="content-page">
          <section id="about" className="about">
            <div className="get-started">
              <br/>
              <br/>
              <h4 style={{"color":"red"}}>Oops!!! Page not Found</h4>
              <p style={{"color":"red"}}>
                What you are trying to look for is not available for now
              </p>
              <p>You can signin or get yourself an account</p>
              <br/>
              <br/>
              <Link className="illustration" to="/register">Get Started</Link>
              <Link className="illustration" to="/login">Login</Link>
            </div>
          </section>
            <br/>
            <br/>
        </div>
        <Footer />

    </div>
  );
}

export default NotFound;
