
import axios from 'axios';
import { useState } from 'react';
import {Link} from 'react-router-dom';

function AddPost() {
    let mercadouser=null;
    if(localStorage.getItem('mercadouser')===null){
        mercadouser=false
    }
    else{
        mercadouser=JSON.parse(localStorage.getItem('mercadouser'))
    }
    const [postinformation,setPostInformation]=useState('');
    const [uploadedphoto,setUploadedPhoto]=useState('');

    const saveProduct =(e) =>{
        e.preventDefault();

        if(uploadedphoto===""){
            alert("Photo is Missing")
            return
        }

        else if(postinformation.trim()===""){
            alert("Post Information is Missing")
            return
        }

        else if(postinformation.length<8){
            alert("Post Information needs more than eight characters");
            return
        }


        let frmdata=new FormData();
        frmdata.append('postinformation',postinformation);
        frmdata.append('uploadedphoto',uploadedphoto);
        frmdata.append('creator',mercadouser[0]['id']);
        axios.post(process.env.REACT_APP_PHP_URL+"savepost.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
        .finally(()=>{
            clearForm()
        })
    }

    const clearForm =() =>{
        setPostInformation('');
        setUploadedPhoto('');
    }
  return (
    <div>
        <form onSubmit={saveProduct}>
            <div className="inputs-profile">
            <label>Upload Photo</label>
            <input type="file" id="photo" name="photo" onChange={(e) => setUploadedPhoto(e.target.files[0])} required />
            </div>

            <div className="inputs-profile">
                <label>Post Information</label>
                <textarea id="postinformation" name="postinformation" value={postinformation} onChange={(e) => setPostInformation(e.target.value)} rows="5" placeholder="Post Information"></textarea>
            </div>
            
            <button className="btn">Save Post</button>
        </form>
    </div>
  );
}

export default AddPost;