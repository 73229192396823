
import axios from 'axios';
import { useState } from 'react';
import {Link} from 'react-router-dom';

function AddAdvert() {
    let mercadouser=null;
    if(localStorage.getItem('mercadouser')===null){
        mercadouser=false
    }
    else{
        mercadouser=JSON.parse(localStorage.getItem('mercadouser'))
    }
    const [businessname,setBusinessname]=useState('');
    const [postinformation,setPostInformation]=useState('');
    const [uploadedphoto,setUploadedPhoto]=useState('');

    const saveAdvert =(e) =>{
        e.preventDefault();

        if(uploadedphoto===""){
            alert("Photo is Missing")
            return
        }

        else if(businessname.trim()===""){
            alert("Business name is Missing")
            return
        }
        else if(businessname.length<4){
            alert("Business Name needs more than four characters");
            return
        }


        else if(postinformation.trim()===""){
            alert("Advert Information is Missing")
            return
        }

        else if(postinformation.length<8){
            alert("Advert Information needs more than eight characters");
            return
        }


        let frmdata=new FormData();
        frmdata.append('businessname',businessname);
        frmdata.append('postinformation',postinformation);
        frmdata.append('uploadedphoto',uploadedphoto);
        frmdata.append('creator',mercadouser[0]['id']);
        axios.post(process.env.REACT_APP_PHP_URL+"saveadvert.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
        .finally(()=>{
            clearForm()
        })
    }

    const clearForm =() =>{
        setPostInformation('');
        setUploadedPhoto('');
    }
  return (
    <div>
        <form onSubmit={saveAdvert}>
            <div className="inputs-profile">
            <label>Upload Photo</label>
            <input type="file" id="photo" name="photo" onChange={(e) => setUploadedPhoto(e.target.files[0])} required />
            </div>

            <div className="inputs-profile">
                <label>Business Name</label>
                <input type="text" id="businessname" name="businessname" value={businessname} onChange={(e) => setBusinessname(e.target.value)} placeholder="Busines Name" required />
            </div>

            <div className="inputs-profile">
                <label>Advert Information</label>
                <textarea id="postinformation" name="postinformation" value={postinformation} onChange={(e) => setPostInformation(e.target.value)} rows="5" placeholder="Advert Information"></textarea>
            </div>
            
            <button className="btn">Save Post</button>
        </form>
    </div>
  );
}

export default AddAdvert;