
import {Link} from 'react-router-dom';
import Mainmenu from "./Mainmenu";
import Footer from "./Footer";
import mercado_garden_3 from '../images/mercado-garden-3.jpg';
import mercado_pool_3 from '../images/mercado-pool-3.jpg';
import mercado_plants_2 from '../images/mercado-plants-2.jpg';
import mercado_pool_1 from '../images/mercado-pool-1.jpg';
import mercado_garden_5 from '../images/mercado-garden-5.jpg';
import mercado_6 from '../images/mercado-6.jpg';


function Home() {
    document.title='Mecado Escolar Home';
  return (
    <div id="wrapper">
      
      {/* Nav Here */}
      <Mainmenu active='home' />
      <div className="content-page">
          <section id="welcome" className="welcome">
            <div className="welcome-information">
              <h4>Welcome to Mercado Escolar</h4>
              <p>
                Marketplace is an online shop for students in college with surrounding Business Owners
              </p>
              <p>It also provides a platform for student to post articles related to them</p>
              <Link className="illustration" to="/register">Get Started Here</Link>
            </div>
            <div className="welcome-illustration">
              <img src={mercado_garden_3}/>
              <p>A serene Environment for students powered by existence and activities of Marketplace </p>
            </div>
          </section>

          <section id="services" className="services">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z" />
              </svg>
              <h6>Chats</h6>
              <p>Students and Business owners will have an interactive session to that helps to facilitate their business transactions</p>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
              </svg>
              <h6>Reports</h6>
              <p>Specialised and more informative reports and insights are generate by the system on the go.
                Various reports ranging from pie charts, bar graphs and even excell and Pdfs</p>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
              </svg>
              <h6>Business Accounts</h6>
              <p>Business Owners around the school have a chance to create a business account that helps them to sell, market or advertize their products .</p>
            </div>

            <div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.068.157 2.148.279 3.238.364.466.037.893.281 1.153.671L12 21l2.652-3.978c.26-.39.687-.634 1.153-.67 1.09-.086 2.17-.208 3.238-.365 1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
              </svg> 
              <h6>Clubs and Posts</h6>
              <p>There is a huge opportunity for student to share and interact through publish of posts, advertizing their items for sell and joiing club of their choice.</p>
            </div>
          </section>


          <h2 className="section-title">What We Offer in Picture</h2>

          <section id="welcome" className="welcome">
            <div className="welcome-illustration">
              <img src={mercado_pool_3}/>
              <p>Swimming Pools </p>
            </div>
            <div className="welcome-illustration">
              <img src={mercado_pool_1}/>
              <p>Indoor Clubs </p>
            </div>
            
            <div className="welcome-illustration">
              <img src={mercado_garden_5}/>
              <p>A simple Mini Cafee </p>
            </div>
            
            <div className="welcome-illustration">
              <img src={mercado_6}/>
              <p>Shoes in the Market </p>
            </div>
          </section>

        </div>
        <Footer />

    </div>
  );
}

export default Home;
