
import {Link} from 'react-router-dom';
import DashboardMenu from "./DashboardMenu";
import DashboardFooter from "./DashboardFooter";

import mercado_garden_3 from '../../images/mercado-garden-3.jpg';
import mercado_pool_3 from '../../images/mercado-pool-3.jpg';
import mercado_plants_2 from '../../images/mercado-plants-2.jpg';
import mercado_pool_1 from '../../images/mercado-pool-1.jpg';
import mercado_garden_5 from '../../images/mercado-garden-5.jpg';
import mercado_6 from '../../images/mercado-6.jpg';

import mercado_4 from '../../images/mercado-4.jpg';
import mercado_elec_2 from '../../images/mercado-elec-2.jpeg';
import mercado_garden_2 from '../../images/mercado-garden-2.jpg';
import avatar from '../../images/avatar.png';
import avatar3 from '../../images/avatar3.png';
import LoadStudents from '../reusable/LoadStudents';



function Students() {
    document.title='Mecado Escolar Students';
  return (
    <div id="wrapper">
      
      {/* Dashboard Nav Here */}
      <DashboardMenu active='students' />
        <div class="content-page">

            <h2 class="section-title">View student Profles
            <div>
                <form>
                    <div class="inputs">
                        <input type="text" placeholder="Search Student Profile" />
                        <button class="btn">
                            <span class="material-symbols-rounded">
                            search
                            </span>
                        </button>
                        </div>
                    </form>
                </div>
            </h2>

            <LoadStudents />

            {/* <section id="visitor" class="visitor">
            <div class="get-started">
                
                <div class="visitor-illustration">
                    <img src={avatar}/>
                    <p>Student One </p>
                </div>

                <div class="visit-apartment">
                    <Link to="/student/marketplace">Products</Link>
                    <Link to="/student/posts">Posts</Link>
                    <Link to="/student/clubs">Clubs</Link>
                    <Link to="/student/chats">Chat</Link>
                </div>
            </div>

            <div class="get-started">
                
                <div class="visitor-illustration">
                    <img src={avatar}/>
                    <p>studen nenn </p>
                </div>
                
                <div class="visit-apartment">
                    <Link to="/student/marketplace">Products</Link>
                    <Link to="/student/posts">Posts</Link>
                    <Link to="/student/clubs">Clubs</Link>
                    <Link to="/student/chats">Chat</Link>
                </div>
            </div>


            <div class="get-started">
                
                <div class="visitor-illustration">
                    <img src={avatar3}/>
                    <p>Student  Sjj</p>
                </div>
                
                <div class="visit-apartment">
                    <Link to="/student/marketplace">Products</Link>
                    <Link to="/student/posts">Posts</Link>
                    <Link to="/student/clubs">Clubs</Link>
                    <Link to="/student/chats">Chat</Link>
                </div>
            </div>
            
            </section>

            <section id="visitor" class="visitor">
            <div class="get-started">
                
                <div class="visitor-illustration">
                    <img src={avatar}/>
                    <p>Student girlsy </p>
                </div>

                <div class="visit-apartment">
                    <Link to="/student/marketplace">Products</Link>
                    <Link to="/student/posts">Posts</Link>
                    <Link to="/student/clubs">Clubs</Link>
                    <Link to="/student/chats">Chat</Link>
                </div>
            </div>

            <div class="get-started">
                
                <div class="visitor-illustration">
                    <img src={avatar}/>
                    <p>Studne Jkk </p>
                </div>
                
                <div class="visit-apartment">
                <Link to="/student/marketplace">Products</Link>
                <Link to="/student/posts">Posts</Link>
                <Link to="/student/clubs">Clubs</Link>
                <Link to="/student/chats">Chat</Link>
                </div>
            </div>


            <div class="get-started">
                
                <div class="visitor-illustration">
                    <img src={avatar3}/>
                    <p>Student Est</p>
                </div>
                
                <div class="visit-apartment">
                <Link to="/student/marketplace">Products</Link>
                <Link to="/student/posts">Posts</Link>
                <Link to="/student/clubs">Clubs</Link>
                <Link to="/student/chats">Chat</Link>
                </div>
            </div>

            </section>

            <section id="visitor" class="visitor">
            <div class="get-started">
            
            <div class="visitor-illustration">
                <img src={avatar}/>
                <p>Stdent Yop </p>
            </div>

            <div class="visit-apartment">
                <Link to="/student/marketplace">Products</Link>
                <Link to="/student/posts">Posts</Link>
                <Link to="/student/clubs">Clubs</Link>
                <Link to="/student/chats">Chat</Link>
            </div>
            </div>

            <div class="get-started">
            
            <div class="visitor-illustration">
                <img src={avatar}/>
                <p>Student Us </p>
            </div>
            
            <div class="visit-apartment">
                <Link to="/student/marketplace">Products</Link>
                <Link to="/student/posts">Posts</Link>
                <Link to="/student/clubs">Clubs</Link>
                <Link to="/student/chats">Chat</Link>
            </div>
            </div>


            <div class="get-started">
            
            <div class="visitor-illustration">
                <img src={avatar3}/>
                <p>Student thet</p>
            </div>
            
            <div class="visit-apartment">
                <Link to="/student/marketplace">Products</Link>
                <Link to="/student/posts">Posts</Link>
                <Link to="/student/clubs">Clubs</Link>
                <Link to="/student/chats">Chat</Link>
            </div>
            </div>

            </section> */}


        </div>
        <DashboardFooter />

    </div>
  );
}

export default Students;
