
import {Link} from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import mercado_4 from '../../images/mercado-4.jpg';

function LoadMarkets({ownerid}) {
    let mercadouser=null;
    if(localStorage.getItem('mercadouser')===null){
        mercadouser=false
    }
    else{
        mercadouser=JSON.parse(localStorage.getItem('mercadouser'))
    }

    const [businesses,SetBusinesses]=useState([]);
    const [productQuantity, setQuantity] = useState(0);
    
    useEffect(()=>{
        let success=true;
        let load='';
        if(ownerid===''){
            load="loadallproducts.php";
            console.log(ownerid)
        }
        else{
            load="loadproducts.php";
        }

        

        

        const getYourProducts = async (e) => {
            axios({
                method:'GET',
                url:process.env.REACT_APP_PHP_URL+load,
                params:{
                    owner:(ownerid==='')?mercadouser[0]['id']:ownerid,
                },
                headers:{
                    'content-type':'application/json',
                }
            })
            .then(response=>{
                if (success) {
                    if(response.data.response){
                        SetBusinesses(response.data.products);
                    }
                    else{
                        SetBusinesses([]);
                    }
                }
            })
            .catch(error=>{
                SetBusinesses([]);
            })
        };
        getYourProducts();

        return ()=>{
            success=false;
        }

    },[businesses])



    const onOrder = (product) => {
        let frmdata=new FormData();
        frmdata.append('product',product.id);
        frmdata.append('seller',product.owner);
        frmdata.append('price',product.productprice);
        frmdata.append('quantity',productQuantity);
        frmdata.append('buyer',mercadouser[0]['id']);
        axios.post(process.env.REACT_APP_PHP_URL+"savecartitem.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }

    const onDelete = (product) => {
        let frmdata=new FormData();
        frmdata.append('id',product.id);
        frmdata.append('buyer',mercadouser[0]['id']);
        axios.post(process.env.REACT_APP_PHP_URL+"deleteproducts.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
    }
    

  return (
    <>
    <section id="visitor" class="visitor">
    {businesses && businesses.map((product,key) => (
        <>
            {mercadouser[0]['role']==="Student" && product.owner!==mercadouser[0]['id'] &&
            <div className="get-started" key={key}>
                <h2 className="started-title">{product.businessname}
                <p>{product.productname} (${product.productprice})</p>
                </h2>
                <div className="visitor-illustration">
                    <img src={process.env.REACT_APP_PHP_URL+product.uploadedphoto}/>
                    <p>{product.productdescription} </p>
                    <span> </span>
                </div>

                <form>
                    <div className="inputs">
                        <input type="number" min="0" max={product.productstock} required onChange={(e) =>setQuantity(e.target.value)} placeholder="Quantity" />
                        <button type='button' className="btn" onClick={() => onOrder(product)}>Order</button>
                    </div>
                </form>

                <div className="visit-apartment">
                    <Link to="/student/chats">Chat</Link>
                </div>
            </div>
            }

            {mercadouser[0]['role']!=="Student" &&
                <div className="get-started" key={key}>
                    <h2 className="started-title">{product.businessname}
                    <p>{product.productname} (${product.productprice})</p>
                    </h2>
                    <div className="visitor-illustration">
                        <img src={process.env.REACT_APP_PHP_URL+product.uploadedphoto}/>
                        <p>{product.productdescription} </p>
                        <span> Sold:{product.sold}</span>
                    </div>

                    <div className="inputs">
                        <button type='button' className="btn" onClick={() => onDelete(product)}>Delete</button>
                    </div>
                    
                </div>
            }
        </>
                                           
    ))} 
        
    </section>  


        
    </>
    
    
  );
}

export default LoadMarkets;