
import {Link} from 'react-router-dom';
import DashboardMenu from "./DashboardMenu";
import DashboardFooter from "./DashboardFooter";

import mercado_garden_3 from '../../images/mercado-garden-3.jpg';
import mercado_pool_3 from '../../images/mercado-pool-3.jpg';
import mercado_plants_2 from '../../images/mercado-plants-2.jpg';
import mercado_pool_1 from '../../images/mercado-pool-1.jpg';
import mercado_garden_5 from '../../images/mercado-garden-5.jpg';
import mercado_6 from '../../images/mercado-6.jpg';

import mercado_4 from '../../images/mercado-4.jpg';
import mercado_elec_2 from '../../images/mercado-elec-2.jpeg';
import mercado_garden_2 from '../../images/mercado-garden-2.jpg';
import LoadMarkets from '../reusable/LoadMarkets';



function Marketplace() {
    document.title='Mecado Escolar Marketplace';
  return (
    <div id="wrapper">
      
      {/* Dashboard Nav Here */}
        <DashboardMenu active='business' />
            <div className="content-page">
                <h2 className="section-title">Business Products
                    <div>
                        <form>
                            <div className="inputs">
                                <input type="text" placeholder="Search Products" />
                                <button className="btn">
                                    <span className="material-symbols-rounded">
                                    search
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </h2>
            
                <LoadMarkets ownerid='' />
                
            </div>
        <DashboardFooter />

    </div>
  );
}

export default Marketplace;
