
import axios from 'axios';
import { useState } from 'react';
import {Link} from 'react-router-dom';

function AddClub() {
    let mercadouser=null;
    if(localStorage.getItem('mercadouser')===null){
        mercadouser=false
    }
    else{
        mercadouser=JSON.parse(localStorage.getItem('mercadouser'))
    }
    
    const [clubname,setClubName]=useState('');
    const [clubinformation,setClubInformation]=useState('');
    const [uploadedphoto,setUploadedPhoto]=useState('');

    const saveProduct =(e) =>{
        e.preventDefault();

        if(clubname.trim()===""){
            alert("Club name is Missing")
            return
        }
        else if(clubname.length<4){
            alert("Club Name needs more than four characters");
            return
        }

        else if(uploadedphoto===""){
            alert("Photo is Missing")
            return
        }

        else if(clubinformation.trim()===""){
            alert("Club Information is Missing")
            return
        }

        else if(clubinformation.length<8){
            alert("Club Information needs more than eight characters");
            return
        }


        let frmdata=new FormData();
        
        frmdata.append('clubname',clubname);
        frmdata.append('clubinformation',clubinformation);
        frmdata.append('uploadedphoto',uploadedphoto);
        frmdata.append('owner',mercadouser[0]['id']);
        axios.post(process.env.REACT_APP_PHP_URL+"saveclub.php",frmdata,null)
        .then(response=>{
            alert(response.data)
        })
        .catch(error=>{
            alert(''+error)
        })
        .finally(()=>{
            clearForm()
        })
    }

    const clearForm =() =>{
        setClubName('');
        setClubInformation('');
        setUploadedPhoto('');
    }
  return (
    <div>
        <form onSubmit={saveProduct}>
            <div className="inputs-profile">
            <label>Upload Photo</label>
            <input type="file" id="photo" name="photo" onChange={(e) => setUploadedPhoto(e.target.files[0])} required />
            </div>

            <div className="inputs-profile">
                <label>Club Name</label>
                <input type="text" id="clubname" name="clubname" value={clubname} onChange={(e) => setClubName(e.target.value)} placeholder="Club Name" required />
            </div>
            <div className="inputs-profile">
                <label>Club Information</label>
                <textarea id="clubinformation" name="clubinformation" value={clubinformation} onChange={(e) => setClubInformation(e.target.value)} rows="5" placeholder="Club Information"></textarea>
            </div>
            
            <button className="btn">Save Club</button>
        </form>
    </div>
  );
}

export default AddClub;